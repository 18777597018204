.taskItemsTableRow {
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
}

.taskItemsTableRow.light-mode {
    background-color: var(--poRescheduleBtn-hover-light);
}

.taskItemsTableRow.dark-mode {
    background-color: transparent;
    border: 1px solid var(--poRescheduleBtn-hover-light);
}

.taskItemTitle {
    font-size: 14px;
}

.taskItemTitle .light-mode {
    color: var(--dark);
}

.taskItemTitle .dark-mode {
    color: var(--light);
}

.taskItemIcon{
    width: 30px;
}

.taskItemsTableRow.light-mode.disabled {
    opacity: 0.5;
}

.taskItemsTableRow.dark-mode.disabled {
    opacity: 0.5;
}