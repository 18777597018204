.modal,
.overlay {
  width: auto;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 01;
}

.overlay {
  background: var(--modalColor);
}

.modalcontent {
    max-height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: var(--white);
    padding: 14px 2rem;
    border-radius: 1rem;
    width: 70vw;
    overflow-y: scroll;
  }

  @media screen and (max-width: 1024px)  {
    .modalcontent {
      max-height: 95vh;
      width: 85vw;
     
    }
  
  }
  @media screen and (max-width: 768px)  {
    .modalcontent {
      max-height: 95vh;
      width: 95vw;
     
    }
  
  }
