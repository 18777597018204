.btnAction{
    border : 0px solid var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.btnDelete{
    background: var(--red);
}

.btnEnable{
    background-color: var(--selected-door-border);
}

.subscriptionList{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    min-height: 55vh !important;
    max-height: 67vh !important;
}