.appt-container {
    border-radius: 14px;
    background: #FFF;
    padding: 10px;
    width: 58vw;
    height: 70vh;
    max-height: 70vh;
}

.w-75 {
    width: 75%;
}

.calender-wrapper {
    width: 75%;

}

@media only screen and (min-width: 600px) and (max-width:1024px) {
    .book-appt-ss{
        flex-direction: row !important;
    }
}

@media only screen and (max-width: 1024px) {
    .appt-container{
        flex-direction: column;
        height: auto;
        width: 100%;
        max-height: none;
    }

}

@media only screen and (max-width: 600px) {
    .manual-override-ss{
        flex-direction: column;
    }
    .calender-wrapper{
        width: 100%;
    }
}