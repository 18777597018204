.d-navbar {
    margin-bottom: -2px;
    padding: 0 !important;
    margin: 0 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.d-navbar.light-mode .d-nav div {
    color: var(--table-hover-dark) !important;
}

.d-navbar.dark-mode .d-nav div {
    color: var(--white) !important;
}

.d-nav {
    border: 4px solid transparent;
}
.d-nav-active {
    border-bottom: 4px solid var(--dark-mode-border-color);
}


.d-nav:hover {
    border-bottom: 4px solid var(--dark-mode-border-color);
    /* border-radius: 5px; */
}

.dd-subnav{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.mainNavigationBar .dropdown{
    border: none !important;
    padding: 0px 4px !important;
    margin: 0px 4px !important;
}

.mainNavigationBar .dropdown .dropDownPlaceholder{
    margin-bottom: -2px;
    padding: 0 4px !important;
    margin: 0 !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

.dropDownNavBarTitle{
    font-size: 16px !important;
}