.btnAction {
    border: 0px solid var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.btnDelete {
    background: var(--red);
    color: var(--white) !important;
}

.btnEnable {
    background-color: var(--selected-door-border);
}

.subscriptionList-ss {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    min-height: 75vh !important;
    max-height: 75vh !important;
}

.my-subscription-ss-wrapper {
    min-height: 90vh;
    height: 95vh;
    width: 100%;

    border-radius: 14px;
    background: #FFF;
}

.my-subscription-ss-wrapper * {
    color: #393939;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;

    /* 93.75% */
    letter-spacing: 0.08px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.SStextSub {
    color: gray;
}

.cancel-subscriptions-ss {
    color: #FFF !important;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 142px;
    height: 32px;
    border-radius: 5px;
    border: 0.5px solid #EB3134;
    background: rgba(235, 49, 52, 0.80);
}

.change-card-container {
    width: auto;
    max-width: 45vw;
}