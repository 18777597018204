.buttonFunnelDash {
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    padding: 4px 12px;
    line-height: 21px;
}

.selectedButton {
    background-color: var(--table-selection-text-light);
    color: var(--white);
}

.unselectedButton {
    background-color: var(--white);
    border: 1px solid var(--table-header);
}

.funnelGraphContainer{
    height: 300px;
    cursor: pointer !important;
}

.funnelGraphdisabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
}

.liveloads {
    margin-bottom: 10px;
    font-size: large;
    color: var(--black)
}

.backhauls {
    font-size: 10px;
    margin-left: 40px;
    margin-top: "5%";
}