.warehouseMeta-wrapper-ss {
    background: var(--pure-white);
    border-radius: 15px;
    height: 95vh;
}

.warehouseMeta-wrapper-ss * {

    font-family: Inter;

    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.customDatePickerInput-ss {
    display: flex;
    background-color: var(--pure-white);
    border-radius: 5px;
    height: 39px;
    border: 0.8px solid var(--light-grey);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    align-items: center;
    padding: 5px;
    color: var(--primary-text);
    width: 100%;
}
.manage-appt-wrapper{
    overflow-y: auto;
    max-height: 85vh;
}

.cb-p{
    padding-right: 10px;
}