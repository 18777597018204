.logo-wrapper img {
    width: 180px;
    height: auto;
}

.nav-list {
    list-style: none;
    padding: 10px 0px;
    font-size: 16px;
    margin: 0;
}

.nav-list li {
    padding: 10px 10px;
    margin: 5px 0;
    width: 100%;
    cursor: pointer;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
    position: relative;
}


.nav-list li a {
    text-decoration: none;
    color: var(--black);
}

.nav-list li a div {
    /* padding: 10px 10px; */
    margin: 5px 0;
    width: 100%;
    cursor: pointer;
}

.nav-list li:hover {
    border-radius: 6px;
    background: var(--pure-white);
    /* transform: scale(1.0155); */
}

.nav-list-selected {
    border-radius: 6px;
    background: var(--pure-white);
    padding: 10px 10px;
    margin: 5px 0;
    width: 100%;
    cursor: pointer;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
    position: relative;
    /* transform: scale(1.0155); */
}

/* .nav-list li:hover .nav-list li i {
    color: #0DAECB;
} */

.account-nav {
    border-radius: 6px;
    background: var(--pure-white);
}

.account-nav img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

p {
    margin: 0;
}

.account-nav-head {
    color: #393939;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.account-nav-sub {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 166.667% */
    letter-spacing: 0.045px;
}

.sub-nav-ss {
    position: absolute;
    left: 100%;
    bottom: 0;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: var(--pure-white);
    box-shadow: 0px 2px 8px 0px rgba(60, 60, 60, 0.10);
    width: 222px;
    z-index: 99;

}

.sub-nav-list {
    list-style: none;
    padding: 8px;
}
@media only screen and (max-width: 1024px) {
.navbar-web{
    display: none !important;
}
}