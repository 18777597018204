.colourIndicator {
    border: 0px solid none !important;
    width: 20px !important;
    height: 20px !important;
    margin: 0px 10px;
}

.colourIndicatorCharacter{
    border: 0.5px solid var(--dark) !important;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;
}

.textInput {
    border: 0.5px solid var(--dark) !important;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: start;
    font-size: 14px;
}

.labelText {
    font-size: 14px;
    text-align: center;
    margin-right: 20px;
}

.statusButtonMeta {
    border : 0px solid var(--white);
    padding: 8px 16px;
    background: var(--table-selection-text-light);
    border-radius: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: var(--white);
    width: 9%;
    text-align: center;
    margin-right: 50%;
    margin-top: 7%;
    margin-left: auto;
    cursor: pointer;
}

.disabledEdit{
    cursor: not-allowed;
    background-color: var(--table-header);
    color: var(--dark);
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(80px, auto);
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 60vh !important;
}

@media (max-width: 1270px) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}
