.statusColorIcon{
  height : 2rem;
}

.showLine.line-light-mode {
  height:2px;
  margin-top: -1.5rem;
  background:
    repeating-linear-gradient(to right, var(--black) 0,var(--black) 5px,transparent 5px,transparent 7px);
  }

.showLine.line-dark-mode {
    height:2px;
    margin-top: -1.5rem;
    background:
      repeating-linear-gradient(to right, var(--white) 0,var(--white) 5px,transparent 5px,transparent 7px);
}

.hideLine {
  height:2px;
  margin-top: -1.5rem;
  background:
    repeating-linear-gradient(to right, transparent 0,transparent 5px,transparent 5px,transparent 7px);
}