.selectedIcon{
    height : 5%;
    width : 5%;
}

.companyGroupList{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 60vh !important;
}
