.recovertitle {
    width: 90%;
}

.recovertitle .t1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.recovertitle .t2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.tryLoginText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}