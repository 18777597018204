/* .pOSection{
    display: flex;
    flex-direction: row;
} */
.po-warpper{
    height: 100%;
    border-top:  1px solid var(--load-header-border);
}

.po-warpper > div.dark-mode{
    background-color: var(--dark);
}