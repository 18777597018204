.searchInputTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.searchBtnApptLookUp{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    background-color: var(--primary);
    color: var(--white) !important;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--primary);
    border-color: transparent;
    width: auto;
}

.poImageHyperlinkText{
    font-size: 12px;
}

.guardtextcenter span {
    text-align: center;
    font-size: 16px;
}