.taskItemTitle .light-mode{
    color: var(--dark);
}

.taskItemTitle .dark-mode{
    color: var(--light);
}

.cancelButton{
    cursor: pointer;
    background-color: violet;
}