.inputTitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.customTextInput {
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    width: 100%;
    padding: 16px;
    color: var(--header-item-color);
}

.customTextInput.customTextInputError {
    border-color: var(--error);
}

.color-red {
    color: var(--error);
}

.errorMessage {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--error);
    margin-top: 2%;
}

.input-container {
    width: 100%;
    position: relative;
}

.input-container>input {
    width: 100%;
    height: 3rem;
    padding: 0.6rem;
    border: 0.8px solid rgba(212, 212, 212, 1);
    border-radius: 5px;
    font: small;
    background-color: white;
    margin-top: -20px;
}

.input-container>input ::placeholder {
    color: rgba(212, 212, 212, 1);
    font-size: 12px;
}