.tableFilterButtons{
    height : auto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.buttonPOFilters:hover
{
    background-image:none;
    color: var(--table-hover-dark);
    background-color: var(--table-hover-light);
}

.buttonPOFilters:active{
    color: var(--white);
    background-color: var(--table-selection-text-light);
    border: 0px;
    box-shadow: none;
}

.buttonPOFilters:focus{
    color: var(--white) !important;
    background-color: var(--table-selection-text-light);
    border: 0px;
    box-shadow: none;
    
}

.dark-mode-poListFilters{
    border: 1px solid ;
    color: var(--white);
    background-color: var(--dark);
}

.dark-mode-poListFilters :hover{
    border: 1px solid ;
    color: var(--table-hover-dark);
    background-color: var(--white);
}

.light-mode-poListFilters{
    border: 1px solid !important;
    color: var(--table-hover-dark);
    background-color: var(--white);
}

.selectedButton {
    color: var(--white) !important;
    background-color: var(--table-selection-text-light);
    border: 0px;
    box-shadow: none;
}

@media (max-width: 540px) {
    .buttonPOFilters { 
        font-weight: 200;
        font-size: 10px;
        padding: 0px;
        margin: 2px;
     }
    
}