.tableContainer-faq-ss{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 75vh;
}


@media only screen and (max-width: 600px) {

    .faq-header-ss{
        flex-direction: column ;
    }
    .faq-header-ss>div, .faq-header-ss>div>div>span>input {
        width: 100% !important;
    }
}
@media only screen and (max-width: 768px) {
    .questionsText, .answerText , .seperatorFaq{
        width: 100% !important;
    }
}