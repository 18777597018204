.secondary-btn-ss {
    border: none;
    outline: none;
    background: none;
    color: var(--blacks);
    font-size: 12px;
}


@media only screen and (max-width: 768px) {
    .po-schedule-ss{

        flex-direction: column;
    }
}
