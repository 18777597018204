.loadHeading {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    border-bottom:  1px solid var(--load-header-border);
}

.loadHeading.light-mode{
    background-color: var(--table-selected-background-light) !important;
}

.loadHeading.dark-mode{
background-color: var(--dark);
}