.po-intake-form{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;
}
.po-intake-form-inside{
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    gap: 2rem;
    padding: 8px 0;
    align-items: center;
}

.po-intake-form-dock{
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    gap: 2rem;
    padding: 8px 0;
    align-items: center;
}