.header {
    height: 10%;
    border-bottom: 1px solid var(--load-header-border);
}

.logoContainer {
    display: flex;
    flex: 1;
}

.logoContainer .logoHeader {
    width: 150px !important;
    height: 75px !important;
    object-fit: contain !important;
    
}
.logoContainerCarrier .login-logo {
    width: 200px !important;
    height: 100px !important;
    object-fit: contain !important;
}
.logoContainer .login-logo {
    width: 300px !important;
    height: 150px !important;
    object-fit: contain !important;
}

.itemsContainer {
    display: flex;
    flex: 1;
    align-items: center;
}

.notificationIndicator {
    cursor: pointer;
    height: 35px !important;
    width: 35px !important;
    margin-top: 10px;

}

.notificationIndicatorChild{
    border-radius: 8px;
    background-color: var(--white);
    width: 30px;
    height: 30px;
    border: none;
}
.notificationIndicator :hover{
    background-color: var(--load-header-border);
}

.notificationIndicator .dropdown-toggle::after {
    content: none !important;
}

.notificationIndicator .dropdown-menu {
    background-color: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px 8px;
    font-size: 14px;
}

.notificationIndicator .dropdown-menu .footer-text{
    font-size: 16px;
    color: var(--table-selection-text-light);
    text-align: end;
    border: none;
    background-color: transparent;
}
