.display-inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pdlft-2{
    padding-left:5px
}
.marrgt-10{
    margin-right: 10px;
}
.marlft-10{
    margin-left: 10px;
}
.marrgt-15{
    margin-right: 15px;
}
.marlft-15{
    margin-left: 15px;
}
.marbt10{
    margin-left: 10px;
}
.margin-bottom-hero-text{
    margin-top:5vh;
    margin-bottom: 5vh;
}
.hero-text{
    font-weight: 600;
font-size: 24px;
line-height: 36px;
color:var(--black);
}
.close-modal{

height: 0.2px;
}
.margin-bottom-equal{
    margin-bottom: 1.5vh; 
}
.horizontal-ruler{
    border: 1px solid var(--table-header);
    margin-bottom: 3vh;
    margin-top: 3vh;
}
.support-details{
font-weight: 600;
font-size: 16px;
line-height: 24px
}
.support{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
}
.tech-support{
    margin-bottom: 20vh;
}
.vb {
    
    border-left: 1px solid var(--table-header);
    height: auto;
  }
  .inputDiv {
    margin-top: 5px;
    width: 90%;
    color: var(--dark) !important;
}

.help-form-group{
    overflow-y: scroll;
    height: 60vh;
}
.border-box{
    border: 1px solid var(--table-header);
    border-left: 0px;
    border-right: 0px;
    width: calc(180px + 13vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
}
.box-main-area{
font-weight: 400;
font-size: 16px;
line-height: 24px;
}
@media screen and (max-width: 1024px)  {
    .support-details {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
    }
}
@media screen and (max-width: 425px)  {
    .mobil-margin{
        margin-right: 5px; 
        margin-left: 5px; 
    }
    .help-form-group{
      overflow-x: hidden;
        height: auto;
    }
    .hero-text{
        font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: black;
    }
    .tech-support {
        margin-bottom: 10vh;
    }
  }
  @media screen and (max-width: 375px)  {
    .mobil-margin{
        margin-right: 2px; 
        margin-left: 2px; 
    }
 
  }
