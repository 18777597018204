.lateLoadsTable{
    border : 1px solid var(--table-header) !important;
    border-radius: 8px;
    background-color: var(--white);
    max-height: 30vh !important;
    overflow: hidden;
    overflow-y: scroll !important;
}

.lateLoadsTable th{
    background-color: var(--customDashboardPlaceholder);
}

.lateLoadsHeader{
    background-color: var(--customDashboardPlaceholder);
    border-radius: 8px 8px 0px 0px !important;
}

.lateLoadsRow :nth-of-type(1){
    border-bottom: 1px solid var(--table-header);
}

.alwaysLate{
    background-color: var(--error);
    border-radius: 8px;
    color: var(--white);
    padding: 4px;
}

.sometimesLate{
    background-color: var(--sometimesLateBackgroundColour);
    border-radius: 8px;
    color: var(--black);
    padding: 4px;
}

.firstTimeLate{
    background-color: var(--firstTimeLateBackgroundColour) !important;
    border-radius: 8px;
    color: var(--white);
    padding: 4px;
}