.file-up-container{
    margin-top: 5px;
   
}

.inst-text{
    font-weight: 400;
font-size: 12px;
line-height: 18px;
color: var(--chooseFiltersTextColor);
}
.file-up-main-sec{
position: relative;
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
}


.file-text{
    margin-left: 5px;
}
.file-rel input[type=file]{
    visibility: inherit;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 250px;
}
input[type=file]::file-selector-button {
    cursor: pointer;
  }
.img-logo{
    margin-left: 5px;
    height: 50px;
    width: 50px;
    border-radius: 50%;

}