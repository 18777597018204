.pODetailsSection {
    text-align: center;
    overflow-y: auto;
    max-height: 64vh;
    overflow-x: hidden;
}

.pODetailsSection.light-mode{
        background-color: var(--table-selected-background-light) !important;
}

.pODetailsSection.dark-mode{
    background-color: var(--dark);
}