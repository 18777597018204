.radioButtonText{
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 400 !important;
}

.radioButtonText .form-check-label{
margin-top: 4px;
}

.radioButtonText .form-check-input{
    margin-top: 8px;
}