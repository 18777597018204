.ratingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 70%;
    margin-top: 15px;
    margin-bottom: 10px;
}

.ratingTitle {
    font-style: 'Poppins';
    font-weight: 400;
    color: var(--dark);
    font-size: 14px;
}

.ratingCountContainer {
    display: flex;
    background-color: var(--light-grey);
    border-radius: 10px;
    height: 16px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.subTitle {
    font-style: 'Poppins';
    font-weight: 400;
    line-height: 18px;
    font-size: 10px;
    margin-left: 10px;
}