.banner-container {
    border-radius: 14px;
    height: 25vh;
    background: var(--banner-bg);
    width: 100%;
}

.banner-head img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.book-appt-horizontal-container {
    border-radius: 14px;
    background: var(--pure-white);
    /* min-height: 200px; */
    width: 50vw;
}

.book-appt-horizontal {
    border-radius: 8px;
    background: var(--navigation);
    height: 100%;

}

.form-control-ss-horizontal-appt {
    width: 150%;
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: var(--pure-white);

    height: 34px;
    padding: 5px;
    color: var(--black);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.submit-btn-ss-horizontal-appt {
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    color: var(--pure-white);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 156px;
    height: 32px;
    border: none;
}

.search-po-heading {
    color: #191919;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    /* 107.143% */
    letter-spacing: 0.07px;
    opacity: 0.3;
}

.banner-img {
    float: right;
    height: 100%;
    border-radius: 15px;
}

.banner-text {
    color: var(--pure-white);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.28px;
}

.banner-heading {
    color: var(--pure-white);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.banner-sub-text {
    color: var(--pure-white);
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 225% */
    letter-spacing: 0.16px;
    margin: 0;
}

.ls-2 {
    letter-spacing: 1.2px;
}

@media only screen and (max-width: 600px) {
   .banner-container{
    height: auto;
    width: auto;
    flex-direction: column;
   }
   .banner-img{
    display: none;
   }
}