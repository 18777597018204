.App{
  min-height: 100vh;
  width: 100%;
  /* overflow-y: auto; */

}
.light-mode{
  background-color: var(--white); 
}
.dark-mode{
  background-color: var(--dark) 
}

*::-webkit-scrollbar {
  width: 10px;   
  height: 8px;            /* width of the entire scrollbar */
}

* .light-mode::-webkit-scrollbar-track {
  background: var(--white);        /* color of the tracking area */
}

* .dark-mode::-webkit-scrollbar-track {
  background: var(--dark);        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid transparent;  /* creates padding around scroll thumb */
}
.cursor-pointer{
  cursor: pointer;
}
