.inputTitle {
    color: #191919;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 15px;
    /* 107.143% */
    letter-spacing: 0.07px;
}

.colourIndicatorText {
    border: 0.5px solid var(--dark) !important;
    height: 25px !important;
    font-size: 12px;
    border-radius: 4px;
    width: 175px !important;
    background-color: transparent;
}

.customTextInput {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    color: black;
    padding: 5px !important;
}

.customTextArea {
    border-radius: 5px !important;
    border: 0.8px solid var(--light-grey) !important;
    background: var(--pure-white) !important;
    color: var(--black) !important;
    padding: 5px !important;
}

.customSelect {
    border-radius: 5px !important;
    border: 0.8px solid var(--light-grey) !important;
    background: var(--pure-white) !important;
    width: 100%;
    height: 39px;
    padding: 5px !important;
}

.paddingSelect {
    padding: 5px 16px;
    color: black;
}

.customError {
    border-color: var(--error);
}

.color-red {
    color: var(--error);
}

.errorMessage {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--error);
    margin-top: 2%;
}

.passwordTextContianer {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    color: black;
    padding: 5px;
}

.radioLabels {
    font-size: 12px;
}

.dateTextInput {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
    margin-left: 5px;
    width: 150px;
}

.dateTextButton {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 10px 16px;
    color: var(--header-item-color);
    margin-left: 5px;
    width: 150px;
}

.selectedBorderColor {
    border-color: var(--firstTimeLateBackgroundColour);
    border-width: 2px;
}