.pODockFiltersSection{
    height : auto;
    margin : -0.3% -2.9% 0% -2.9%;
    padding-left: 2.5%;
    background: var(--white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
}

.poDockStatusTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.dark-mode.dock-filters{
    color: var(--white);
    background-color: var(--dark) !important;
}

.light-mode.dock-filters{
    color: var(--dark);
    background-color: var(--white) !important;
    
}