:root {
    --rescheduled: #FCAA34;
    --scheduled: rgb(74, 150, 74);
    --completed: black;
    --notscheduled: rgb(161, 49, 49);
    --bg-rescheduled: rgba(252, 170, 52, 0.2);
    --bg-scheduled: #C9F8D6;
    --bg-completed: #ccc;
    --bg-notscheduled: rgba(161, 49, 49, 0.2);
}

.carrier-po-wrapper {
    border-radius: 10px;
    background: #F5F9FC;
    width: 100%;
    min-height: 314px;
    min-width: 18vw;
    max-width: 20vw;
}

/* .carrier-po-head {
    border: 1px solid #FEE5AC;
    border-radius: 6px;
    background: rgba(254, 229, 172, 0.20);
    width: 100%;
    height: 50px;

} */

.carrier-po-vl.rescheduled {
    border-left: 1px solid var(--rescheduled);
    height: 100%;
    width: 2px;
}

.carrier-po-vl.completed {
    border-left: 1px solid var(--completed);
    height: 100%;
    width: 2px;

}

.carrier-po-vl.apptScheduled {
    border-left: 1px solid var(--scheduled);
    height: 100%;
    width: 2px;
}

.carrier-po-vl.apptNotScheduled {
    border-left: 1px solid var(--notscheduled);
    height: 100%;
    width: 2px;
}

.color-ss-carrier.rescheduled {
    color: var(--rescheduled) !important;
}

.color-ss-carrier.completed {
    color: var(--completed) !important;
}

.color-ss-carrier.apptScheduled {
    color: var(--scheduled) !important;
}

.color-ss-carrier.apptNotScheduled {
    color: var(--notscheduled) !important;
}


.carrier-ss-head.rescheduled {
    border-radius: 6px;
    background: var(--bg-rescheduled);
    width: 100%;
    height: 50px;

    border: 1px solid var(--rescheduled) !important;
}

.carrier-ss-head.completed {
    border-radius: 6px;
    background: var(--bg-completed);
    width: 100%;
    height: 50px;

    border: 1px solid var(--completed) !important;
}

.carrier-ss-head.apptScheduled {
    border-radius: 6px;
    background: var(--bg-scheduled);
    width: 100%;
    height: 50px;

    border: 1px solid var(--scheduled) !important;
}

.carrier-ss-head.apptNotScheduled {
    border-radius: 6px;
    background: var(--bg-notscheduled);
    width: 100%;
    height: 50px;

    border: 1px solid var(--notscheduled) !important;
}


.carrier-po-time-container i,
.carrier-po-location-container i {
    width: 15px;
}

.carrier-po-month {

    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 150% */
    letter-spacing: 0.05px;
}

.carrier-po-day {

    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 68.182% */
    letter-spacing: 0.11px;
}

.carrier-po-vl {
    border-left: 1px solid #FEE5AC;
    height: 100%;
    width: 2px;
}

.carrier-po-date-container {
    width: 20%;
}

.carrier-po-time-container,
.carrier-po-location-container {
    color: #64460A;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 150% */
    letter-spacing: 0.05px;
}

.carrier-po-key span {
    color: #383838;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 136.364% */
    letter-spacing: 0.055px;
}

.carrier-po-key i {
    color: #848586;
    width: 20px;
}

.carrier-po-value {
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.carrier-po-add__po-btn {
    border-radius: 4px;
    border: 0.5px solid #D8D8D8;
    background: #E5E9ED;
    padding: 5px 8px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* 244.444% */

}

.carrier-po-reschedule-btn {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 12px;
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    border: none;
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
}

.carrier-po-btn-small {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 0.5px solid #E5E5E5;
    background: #FFF;
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
}

.carrier-po-crousal-control {
    border-radius: 5px;
    background: #E5E9ED;
    width: 30%;
}

.carrier-po-crousal-control button {
    border: none;
    background: none;
}

.carrier-po-crousal-control div {
    color: #383838;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.h-87 {
    height: 87%;
}


.carrier-associated-po-wrapper {
    border-radius: 10px;
    background: #F5F9FC;
    width: 100%;
    min-height: 240px;
    min-width: 15vw;
    max-width: 30vw;
}
.column-head {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    min-width: 20vw;
    max-width: 35vw;
    overflow-y: auto;
    margin: 5px;

}

.column-head::-webkit-scrollbar {
    width: 4px;
}

.column-head::-webkit-scrollbar-track {
    border-radius: 10px;
}

.column-head::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--poApproveBtn-hover-light);
}
@media only screen and (max-width: 768px) {
    .carrier-po-wrapper{
        max-width: none;
    }
    .column-head {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 10px;
        min-width: 30vw;
        max-width: 70vw;
        overflow-y: auto;
        margin: 5px;
    
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
    .carrier-po-wrapper,.carrier-associated-po-wrapper{
        min-width: 40vh;
        width: auto;
        max-width: none;
    }
    .column-head {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 10px;
        min-width: none;
        max-width: none;
    }
}

@media only screen and (max-width: 600px) {
   .carrier-associated-po-wrapper{
        min-width: 40vw;
        max-width: 100vw;
    }
    .column-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        min-width: none;
        max-width: none;
    
    }
}
