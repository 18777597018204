.commentRow{
    background-color: transparent !important;
}
.commentRow.light-mode{
    border-top : 1px solid var(--load-header-border);
}
.commentRow.dark-mode{
    border-top : 1px solid var(--load-header-border);
}

.profileIcon{
    width: 6%;
    height: 6%;
}

.nameText{
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background-color: transparent !important;
}
.nameText.light-mode{   
    color: var(--dark);
}
.nameText.dark-mode{
    color: var(--white);
}

.commentText{
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background-color: transparent !important;
    line-height: 21px;
}
.commentText.light-mode{
    color: var(--table-hover-dark);
}
.commentText.dark-mode{
    color: var(--table-hover-light);
}
.dateText{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background-color: transparent !important;
}
.dateText.light-mode{
    color: var(--header-item-color);
}
.dateText.dark-mode{
    color: var(--header-item-color);
}