.inputTitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.customTextArea {
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    height: 60px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
    backface-visibility: hidden !important;
    transform: translateZ(0) !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
}

.customTextArea.customTextAreaError {
    border-color: var(--error);
}

.errorMessage {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--error);
    margin-top: 2%;
}