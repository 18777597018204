
/* style for navbar mobile */

.navbar-mobile{

    display: flex;
    width: 100%;
    z-index: 99999999999;
    background-color: #f5f9fc !important;
    justify-content: space-between;
    /* padding: 10px; */
}

.nav-ham-mobile{
    float: right;
}
.navbar-mobile-header{
    position: sticky;
    top: -1rem;
    display: flex;
    width: 100vw;
    z-index: 999;
    background-color: #f5f9fc !important;
    justify-content: space-between;
    /* padding: 10px; */
}
.navbar-mobile-body{
    position: absolute;
    top: 100%;
    width: 100%;
}
.nav-list-mobile{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    list-style: none;
    width: 100%;
}
.nav-list-mobile li{
    padding: 10px 10px;
    /* margin: 5px 0; */
    width: 100%;
    cursor: pointer;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
    position: relative;
}
.sub-nav-ss-mobile{
    position: absolute;
    right: 0;
    top: 100%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: var(--pure-white);
    box-shadow: 0px 2px 8px 0px rgba(60, 60, 60, 0.10);
    width: 222px;
    z-index: 99;
}
.account-nav-mobile{
    display: flex;
    flex-direction: row-reverse;
}
.mobile-header-logo img{
    height: 65px;
    width: auto;
}
.mobile-nav-toggle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
}

@media only screen and (min-width: 1024px) {
    .navbar-mobile-header{
        display: none;
    }

}