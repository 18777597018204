#left-block-twt {
    background-image:
                linear-gradient(45deg,
                    rgba(0, 0, 0, 0.75),
                    rgba(0, 0, 0, 0.75)), 
                      url('../../../../../../../Assests/Images/truck.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 50%;
    border-radius: 1rem;

}


.fw-800 {
    font-weight: 800 !important;
}

.ss-signup-top-para {

    width: auto;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1px;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.text-container-ss {
    /* position: relative; */
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 2;
    padding: 1.5rem;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.bottom-section {
    position: absolute;
    bottom: 0;
    padding: 1.5rem;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
}

.bottom-text-content-ss {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 175% */
    letter-spacing: 0.8px;
    list-style: none;
}

/*  Small devices (portrait tablets and large phones, 768px) */
@media only screen and (max-width: 768px) {
    #left-block-twt{
        width: 100%;
    }
    .truckwithtext-bottom{
        flex-direction: column;
    }
    #btn-container{
        justify-content: space-between;
    }
    .truckwithtext-bottom > div {
        margin-top: 10px;
    }
}

/* Extra Small devices (portrait tablets and large phones, 600px) */
@media only screen and (max-width: 600px){
    #btn-container{
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}