.my-modal-content, .my-modal-header, .my-modal-footer {
    border: none !important;
}

.modal-dialog.light-mode, .modal-dialog.dark-mode{
     background-color: transparent !important;
}

.modal-dialog.light-mode .modal-header, .modal-dialog.dark-mode .modal-header{
    background-color: var( --table-selection-text-light);
    color: var(--white);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    
}


.modal-dialog.light-mode .modal-body{
    color: var(--dark);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    overflow-y: auto !important;
}

.modal-dialog.dark-mode .modal-body{
    background-color: var(--dark);
    color: var(--white);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.modal-dialog.dark-mode .modal-footer{
    border: none;
    background-color: var(--dark);
}

.noScrolling{
    overflow-y: hidden !important;
    height : 90vh !important;
}

.allowScrolling{
    overflow-y: auto !important;
    height : 50vh !important;
    overflow-x: hidden;
}

.createUserPopup{
    overflow-y: auto !important;
    height : 70vh !important;
    overflow-x: hidden;
}

.createAdminPopup{
    overflow-y: auto !important;
    height : 50vh !important;
    overflow-x: hidden;
}

.my-modal-header{
    background-color: var( --table-selection-text-light);
    color: var(--white);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    
}