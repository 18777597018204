.searchWarehouseInput-ss {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    height: 32px;
    width: 236px;

}

input:focus-visible {
    outline: none;
    border-radius: none;
}

.cancelSearchIcon {
    cursor: pointer;
}

.searchWarehouseInputField-ss {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    width: 236px;
    height: 32px;
    padding-left: 2rem;

}

.search-icon-warehouse {
    position: absolute;
    top: 13px;
    left: 10px;
}