.warehouse-header-ss {
    border-radius: 6px;
    background: #FFF;
}

.warehouseCardText-ss {
    border-radius: 10px;
    background: var(--navigation);
}

.warehouseCardText-ss * {
    color: #383838;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 107.143% */
    letter-spacing: 0.07px;
}

.executor-ss {
    border-radius: 14px;
    background: #FFF;
    height: 90vh;
}

.warehosue-card-wrapper {
    border-radius: 10px;
    background: var(--navigation);
    width: 20vw;
}

.f-20 {
    font-size: 20px;
}

.btn-logout {
    border-radius: 4px;
    background: linear-gradient(180deg, #FF0000 0%, #FF0000 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    padding: 5px 10px;
    color: var(--pure-white) !important;
    min-width: 120px;
    min-height: 32px;
    border: none;
    font-size: 14px;
}