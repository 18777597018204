.picker-wrapper {
    width: 100%;

    border-radius: 8px;
    padding: 0.5rem;
}

.picker-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    background: #F2F6F5;
    padding: 0.5rem;
}

.picker-body {
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 6px;
}

.picker-day {
    border-radius: 6px;
    border: 0.4px solid transparent;
    color: #4FA2B4;
    background: rgba(61, 190, 213, 0.1);
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.20) inset;
    width: 36px;
    height: 36px;

    cursor: pointer;
}

.picker-day:hover {
    border-radius: 6px;
    border: 0.4px solid #4fa2b4;
    background: #3dbed5;
    color: white;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.2) inset;
}

.day-active {
    border: 0.4px solid #4fa2b4;
    background: #3dbed5;
    color: white;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.2) inset;
}

.picker-day-disable {
    border: 0.4px solid transparent;
    background: transparent;
    color: grey;
    opacity: 0.5;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.2) inset;
    border-radius: 6px;
    width: 36px;
    height: 36px;
}

.picker-navigation {
    background: white;
    outline: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    box-shadow: 0px 0px 2px black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.code_date_available{
    background: rgba(61, 190, 213, 0.2);
    height: 15px;
    width: 15px;
    border-radius: 4px;
}
.code_date_selected{
    background: #3dbed5;
    height: 15px;
    width: 15px;
    border-radius: 4px;
}