.buttonHelpSupport {
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    padding: 4px 12px;
    line-height: 21px;
    cursor: pointer;
}

.selectedButton {
    background-color: var(--table-selection-text-light);
    color: var(--white);
}

.unselectedButton {
    background-color: var(--white);
    border: 1px solid var(--table-header);
}