form {
    width: 100%;
}


.button-bg {
    background-color: #10C6F5;
    border: 1px solid #0081A1;
    padding: 10px 15px 10px 15px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    max-height: 40px;
}
#logo-holder-signup{
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo-holder-signup img {
    width: 40%;
}

.carrier-signup-wrapper-ss {
    background-image: url(../../../Assests/Images/login-bg.png);
    background-repeat: repeat;
    background-size: contain;
    flex-direction: row-reverse;
}

.button-bg-none {
    background-color: transparent;
}

.imp-text {
    color: #10C6F5;
}

#required {
    color: red;
}

.full-width {
    width: 100%;
}

.text-sm {
    font-size: 0.9rem;
}

.text-gray {
    color: #191919;
    font-weight: 500;
    font-size: 13px;
}

@media screen and (max-width : 1440px) {
    #form-btns {
        font-size: 13px;
    }

    #input-label {
        font-size: 16px;
    }

    input,
    select {
        font-size: 14px;
    }
}

@media screen and (max-width : 1024px) {

    #input-label {
        font-size: 15px;
    }

    input,
    select {
        font-size: 13px;
    }
}

.signup-form-section {
    width: 90%;
    margin: auto;
    overflow-y: auto;
    max-height: 65vh;
}

.payment-form-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 80%;
    margin: auto;
}

.input-control {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    padding: .5rem;
    height: 40px;
}

.btn-main {
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    width: 170px;

}

.no-bg {
    background: none;
}

.no-border {
    border: none;
}

.btn-alter {
    color: #191919;
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    /* 13.44px */
}
#carrier__signup{
    flex: 1 1;
}

.signup-form-section::-webkit-scrollbar {
    width: 6px;
  }
  
  .signup-form-section::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .signup-form-section::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--primary-new);
  }
.password__feild input::-ms-reveal { 
    display: none; 
}

/*  Small devices (portrait tablets and large phones, 768px) */
@media only screen and (max-width: 768px) {
    .carrier-signup-wrapper-ss{
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .signup-form-section{
        overflow-y: hidden;
        width: 100%;
        height: auto;
        max-height:none !important;
        margin-bottom: 1rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .signup-form-section {
        width: 95%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}