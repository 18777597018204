thead{
    height: 2%;
}
thead th {
    position: sticky;
    top: 0; 
    z-index: 99;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    border-bottom:  1px solid var(--load-header-border);
    /* height: 2%; */
    border-bottom:  1px solid var(--load-header-border);
}

.light-mode {
    background-color: var(--white) ;
    color: var(--dark);
}

.dark-mode {
    background-color: var(--dark);
    color: var(--white);
}

.truck-list-title .light-mode{
    background-color: var(--white);
}

.truck-list-title .dark-mode {
    background-color: var(--dark);
}

thead tr.dark-mode{
    border-color: var(--table-header) !important;
}

tr i {
    color: var(--table-header) !important;
}

.table-title-checkBox >input[type="checkbox"] {
    height: 16px;
    width: 16px;
}

.title-text-container{
    width: 10vw;
}