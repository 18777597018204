.carrierApptBtn{
    border : 0px solid var(--white);
    padding: 8px 16px;
    
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.carrierApptBtn.rescheduled {
    background:  rgb(255, 102, 0);
}

.carrierApptBtn.scheduled {
    background: rgb(0, 153, 51) !important;
}

.horizontalSeperator.scheduled{
    height: 10px !important;
    margin-right: 15px;
    background-color: rgb(0, 153, 51);
}

.horizontalSeperator.rescheduled{
    height: 10px !important;
    margin-right: 15px;
    background-color:  rgb(255, 102, 0);
}

.horizontalSeperator.completed{
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--black);
}
.horizontalSeperator.apptScheduled{
    height: 10px !important;
    margin-right: 15px;
    background-color: rgb(0, 153, 51);
}
.horizontalSeperator.apptNotScheduled{
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--red);
}
.list-size{
    font-size: 22px;
}