.buttonReport {
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    padding: 4px 12px;
    line-height: 21px;
    cursor: pointer;
}

.unselectedButton {
    background-color: var(--white);
    border: 1px solid var(--table-header);
}

.selectedButton {
    background-color: var(--table-selection-text-light);
    color: var(--white);
}

.customDatePickerInput {
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    align-items: center;
    padding: 4px;
    color: var(--header-item-color);
    width: 100%;
}

.reportLogoContainer {
    display: flex;
    flex: 1;
}

.reportLogoContainer .report-logo {
    width: 250px !important;
    height: 150px !important;
    object-fit: contain !important;
}