.img-wrapper {
    padding: 25px;
    background-color: var(--table-empty-text-color);
    color: var(--white);
  }
  .profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }
  .profile-pic input {
    display: none;
  }
  .profile-pic img {
    position: absolute;
    object-fit: cover;
    width: 120px;
    height: 120px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    border-radius: 100px;
    z-index: 0;
  }
  .profile-pic .-label {
    cursor: pointer;
    height: 120px;
    width: 120px;
  }
  .profile-pic:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
  }
  .profile-pic span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }