.inputComments{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;
    isolation: isolate;
    background: var(--white);
    border: 1px solid var(--table-border-color);
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
    resize: none;
}

.buttonsSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 12px;
    width: 20%;
    margin-bottom: 7vh !important;
}

.addButton {
    border : 0px solid var(--white);
    padding: 8px 16px;
    background: var(--table-selection-text-light);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.cancelButton {
    border : 0px solid var(--white);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background-color: transparent !important;
}

.cancelButton.light-mode {
    color: var(--dark);
}

.cancelButton.dark-mode {
    color: var(--light);
}

.characterLengthErrorMessage{
    font-size: 12px;
    color: var(--error);
    text-align: start;
    width: auto;
    margin-top: 4px;
}

.deleteButton{
    border : 0px solid var(--white);
    padding: 8px 16px;
    background: var(--red);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}
.enableButton{
    border : 0px solid var(--white);
    padding: 8px 16px;
    background: var(--selected-door-border);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}