.downloadPOTemplateLink {
    font-size: 14px;
}

.uploadPOButotnWidget {
    border: 1px solid var(--dark);
    padding: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;
    background-color: transparent !important;
}

.createPOButton {
    border: 0px solid var(--white);
    padding: 8px 16px;
    background: var(--table-selection-text-light);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.inputFileUploadCreatePO {
    max-width: 15vw !important;
}

.weightsParentContainer {
    margin-left: 2.5%;
}

.itemsCasesParentContainer {
    width: 23.7%;
}

.createPo-wrapper-ss {
    background: var(--pure-white);
    border-radius: 15px;
    height: 100%;
}

.btn-download-template-ss {
    border-radius: 5px;
    border: 0.5px solid #E5E5E5;
    background: #FFF;
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
    text-decoration: none;
    color: var(--black);
    width: 275px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulk-upload-wrapper {
    background: var(--pure-white);
    border-radius: 15px;
    height: 100%;
}

.dropDownDownItemloadSection-ss {
    background: var(--pure-white) !important;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}