.truck-list-title-ss th {
    border-radius: 5px !important;
    background: var(--navigation) !important;
}

.SS-drilldown-body tr td {
    background: var(--pure-white);
}

.SS-drilldown-body tr:hover {
    background: var(--navigation) !important;
}

.font-default i {
    font-family: 'FontAwesome' !important;
    font-style: normal !important;

}

.table-status-icon img {
    height: 20px;
    width: 20px;
}

.table-status-icon {
    border: 0.8px solid var(--light-grey);
    border-radius: 6px;
    padding: 6px 8px;
}

.SS-drilldown-body::-webkit-scrollbar {
    width: 6px;

}

.SS-drilldown-body::-webkit-scrollbar-track {

    border-radius: 10px;
}

.SS-drilldown-body::-webkit-scrollbar-thumb {
    border-radius: 10px;


    background: var(--primary-new);
}
.width-50px{
    width: 50px;
}