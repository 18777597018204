.appt-history-body{
    font-size: 12px;
}
.appt-history-body td{
    font-size: 12px;
    word-break: break-word;
    padding: 1px;
}
.history-wrapper{
    overflow-y: auto;
    max-height: 32vh;
}


.history-wrapper::-webkit-scrollbar {
    width: 6px;
  
  }
  
  .history-wrapper::-webkit-scrollbar-track {
  
    border-radius: 10px;
  }
  
  .history-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
  
  
    background: var(--primary-new);
  }