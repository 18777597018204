.containerLeftInfoForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    overflow-y: scroll;
    max-height: 90vh !important;
    overflow-x: hidden;
}

.pdlft-2 {
    padding-left: 5px
}

.marrgt-10 {
    margin-right: 10px;
}

.marlft-10 {
    margin-left: 10px;
}

.marrgt-15 {
    margin-right: 15px;
}

.marlft-15 {
    margin-left: 15px;
}

.marbt10 {
    margin-left: 10px;
}

.margin-bottom-hero-text {
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.hero-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: var(--black);
}

.close-modal {

    height: 0.2px;
}

.margin-bottom-equal {
    margin-bottom: 1.5vh;
}

.horizontal-ruler {
    border: 1px solid var(--table-header);
    margin-bottom: 3vh;
    margin-top: 3vh;
}

.support-details {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px
}

.support {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.tech-support {
    margin-bottom: 20vh;
    font-size: 16px;
}

.vb {

    border-left: 1px solid var(--table-header);
    height: auto;
}

.inputDiv {
    margin-top: 5px;
    width: 90%;
    color: var(--dark) !important;
}

.help-form-group {
    overflow-y: scroll;
    height: 60vh;
}

.border-box {
    border: 1px solid var(--table-header);
    border-left: 0px;
    border-right: 0px;
    width: calc(180px + 13vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;
}

.box-main-area {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (max-width: 1024px) {
    .support-details {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
    }
}

@media screen and (max-width: 425px) {
    .mobil-margin {
        margin-right: 5px;
        margin-left: 5px;
    }

    .help-form-group {
        overflow-x: hidden;
        height: auto;
    }

    .hero-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: black;
    }

    .tech-support {
        margin-bottom: 10vh;
        font-size: 16px;
    }
}

@media screen and (max-width: 375px) {
    .mobil-margin {
        margin-right: 2px;
        margin-left: 2px;
    }

}

.info-sub-headings {
    font-style: normal;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.parentContainerLeftInfo {
    display: flex;
    flex: 1;
    z-index: 1;
    margin-top: 0px;
    position: absolute;
    padding: 2%;
    height: 96%;
    background-color: rgba(255, 255, 255, 0.85);
}

.bgContainerImageLeftInfo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.bgContainerLeftInfo {
    position: relative;
    align-items: center;
    height: 110vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 2%;
}

.infoContainer1{
    margin:4px;
    width: 100%;
    font-size: 14px;
}

.heading1{
    color: var(--table-selection-text-light) !important;
    font-size: 16px;
    font-weight: bolder;
}

.heading1.dark{
    color: var(--table-border-color) !important;
}

.subHeading1{
    color: var(--table-selection-text-light) !important;
    font-size: 14px;
    font-weight: 200;
}

.infoContainer2{
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 3%;
    width: 50%;
    color: var(--table-border-color);
}

.infoContainer3{
    margin-top: 5%;
}

.subHeading2{
    font-size: 12px;
    font-weight: 400;
}

.infoContainer3a{
    width: 50%;
}

.infoContainerContact{

    font-size: 14px;
    font-weight: 400;
}

.contactNum{
    text-decoration: none;
    cursor: pointer;
}

.landingScreenContainerSignUp{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 65vh !important;
}

.seperatorLandingSignUp{
    height: 2px;

    background-color: var(--primary);
    width: 98%;
    margin: 8px 0px 16px 0px;
}