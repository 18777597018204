.landingScreenContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 75vh !important;
}

.seperatorLanding{
    height: 2px;
    background-color: var(--primary);
    width: 100%;
    margin: 8px 0px 16px 0px;
}