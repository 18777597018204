.pOList {
    color: var(--text-color);
}

tr.dark-mode{
    border-color: var(--table-header) !important;
}

tr>td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    white-space: pre-wrap;
    /* css */
    white-space: -moz-pre-wrap;
    /* Mozilla */
    white-space: -pre-wrap;
    /* Chrome*/
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}
tr:last-child{
    border-bottom: 0px solid transparent;
}

tr>td.light-mode{
    color: var(--dark);
}

tr>td.dark-mode {
    color: var(--white);
}

tr>td.dark-mode input[type="checkbox"]{
    background-color: var(--dark) !important;
    
}


.table-hover tbody tr:hover td.light-mode,
.table-hover tbody tr:hover th.light-mode {
    background-color: var(--table-hover-light) !important;
    color: var(--dark);
    cursor: pointer;
}

.table-hover tbody tr:hover td.dark-mode,
.table-hover tbody tr:hover th.dark-mode {
    background-color: var(--table-hover-dark) !important;
    color: var(--white);
    cursor: pointer;
}

.table tbody tr.active.light-mode, .table tbody tr.light-mode.active td{
    background-color: var(--table-selected-background-light) !important;
    color: var(--table-selection-text-light) !important;
}

.table tbody tr.dark-mode.active, .table tbody tr.dark-mode.active td{
    background-color: var(--table-selected-background-dark) !important;
    color: var(--table-selection-text-dark) !important;
}

.po-checkbox-icon-wrapper .po-icon-status {
    width: 24px;
    height: 27px;
}

.no-data-wrappper .no-data-svg{
    height: 50%;
}

.no-data-wrappper .no-data-text1, .no-data-wrappper .no-data-text2{
    height: 10%;
    color: var(--table-empty-text-color);
}

.no-data-wrappper .no-data-text1{
    font-weight: 600;
    font-size: 24px;
}

.no-data-wrappper .no-data-text2{
    font-weight: 400;
    font-size: 16px;
}

.no-data-wrappper .no-data-text1.dark-mode, .no-data-wrappper .no-data-text2.dark-mode{
    color: var(--white)
}

.light-mode .waitingFor15MinOrMore{
    border: 3px solid var(--release-door-in-waiting-light);
}

.dark-mode .waitingFor15MinOrMore{
    border: 3px solid var(--release-door-in-waiting-dark) !important;
}



.isNotDoorAcknowledge{
    color: var(--red) !important;
    font-weight: 500 !important;
}