.carrier-appt-card-wrapper {
    border-radius: 6px;

    width: 100%;
    min-height: 90px;
    padding: 10px;
    color: #14535F;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.aapt-card-scheduled {
    color: var(--scheduled);
    background: var(--bg-scheduled);
}

.aapt-card-completed {
    background: var(--bg-completed);
}

.aapt-card-rescheduled {
    color: var(--rescheduled);
    background: var(--bg-rescheduled);
    /* background: linear-gradient(180deg, #FFDBF5 0%, #FFDAF5 0.01%, #FFDAF5 100%); */
}

.f-10 {
    font-size: 10px;
}

.carrier-appt-card-btn {
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 2px black;
    border: none;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.appt-card-text {
    color: #14535F;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}