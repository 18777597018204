.iFrameButtons {
    max-height: 50px;
}

.cancelPaymentButton {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    background-color: transparent;
    color: var(--dark) !important;
    padding: 10px;
    border-radius: 8px;
    border: none;
}

.payment-heading-text {
    color: #191919;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 75% */
    letter-spacing: 0.1px;
}

.cancelPaymentButton:hover {
    border: 1px solid var(--dark);
}

.iFrameButtons.disabled {
    background-color: var(--table-header);
    border: 0px solid none !important;
    color: var(--white) !important;
    cursor: not-allowed;
}

.iFrameButtons.disabled {
    background-color: var(--table-header);
    border: 0px solid none !important;
    color: var(--white) !important;
    cursor: not-allowed;
    box-shadow: none;
}

.carrierSignUpFormContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
    box-shadow: 2px 2px 7px 1px grey;
    /* border: 0.5px solid grey; */
}

.paytraceIFrame {
    /* box-shadow: 2px 2px 5px 1px grey; */
    flex-direction: row;
    display: flex;
    width: max-content;
    justify-content: center;
    border-radius: 8px;
}

.paymentInfoText {
    color: var(--table-border-color);
    font-size: 14px;
}

.iFrameErrorText {
    font-size: 12px;
}

.carrierSignUpParentContainer {
    max-height: 80vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 4px;
}

.cardsImage {
    width: 210px !important;
    height: 30px !important;
    margin-right: 10px;
}

.cardsCVVImage {
    width: 100px !important;
    height: 60px !important;
}