.questionsText {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--table-empty-text-color);
}

.answerText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--table-empty-text-color);
    max-lines: 100;
}

.seperatorFaq{
    height: 2px;
    background-color: var(--table-header);
    width : 50% !important;
}