.week-scroll *{
    font-family: Inter;
    font-style: normal;
   
  
    /* 93.75% */
    letter-spacing: 0.08px;
}

.table-ss{  
    position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  box-sizing: content-box;

}
.table-ss >div {
  flex-shrink: 0;
  flex-grow: 1;
}
.TableGrid {
    display: grid;
    grid-template-columns: repeat(8,1fr); 
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    z-index: auto;
    border-style: solid;
    border-color: #ccc;
    border-width: 0 0 0 1px;
  }
  
  .TableGrid:first-of-type {
    border-width: 1px 0 0 1px;
  }
  
  .TableGrid:last-of-type {
    border-width: 0 0 1px 1px;
  }
  
  .TableGrid .rs__cell {
    background: #fff;
    position: relative;
    border-style: solid;
    border-color: #ccc;
    border-width: 0 1px 1px 0;
  }
  
  .TableGrid .rs__cell.rs__header > :first-of-type {
    padding: 2px 5px;
  }
  
  .TableGrid .rs__cell.rs__header__center {
    padding: 6px 0;
  }
  
  .TableGrid .rs__cell.rs__time {
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    left: auto;
    z-index: auto;
  }
  
  .TableGrid .rs__cell > button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    cursor: pointer;
  }
  
  .TableGrid .rs__cell > button:hover {
    background: rgba(0, 123, 255, 0.1);
  }
  
  .TableGrid .rs__cell .rs__event__item {
    position: absolute;
    z-index: 1;
  }
  
  .TableGrid .rs__cell .rs__multi_day {
    position: absolute;
    z-index: 1;
    text-overflow: ellipsis;
  }
  
  .TableGrid .rs__cell .rs__block_col {
    display: block;
    position: relative;
  }
  
  .TableGrid .rs__cell .rs__hover__op {
    cursor: pointer;
  }
  
  .TableGrid .rs__cell .rs__hover__op:hover {
    opacity: 0.7;
    text-decoration: underline;
  }
  
  .TableGrid .rs__cell:not(.rs__time) {
    min-width: 65px;
  }
  .custom__event__ss{
    border-radius: 6px;
/* border: 1.4px solid #C53192;
background: #FFF0FB;
color: #C53192; */
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 200% */
padding: 4px;
  }


  .event__rescheduled {
    background: var(--bg-rescheduled);
    border: 1.4px solid var(--rescheduled) !important;
}

.event__completed {
    background: rgba(0, 0, 0, 0.1);
    border: 1.4px solid var(--completed) !important;
}

.event__scheduled {
    background:#C9F8D6;
    color:#35C656;
    border: 1.4px solid var(--scheduled) !important;
}
.event__scheduled__head {
    background:var(--scheduled);
    color:var(--pure-white);
    border: none;
}
.event__rescheduled__head {
    background:var(--rescheduled);
    color:var(--pure-white);
    border: none;
}
.event__completed__head {
    background: slategray;
    color: var(--pure-white);
    border: none;
}
.my-scheduler-ss-wrapper{
  min-height: 90vh;
  height: 95vh;
  width: 100%;
  border-radius: 14px;
  background: #FFF;
  overflow-y: hidden;
  overflow-x: hidden;
}
.rs___break{
  border-radius: 10px;
border: 1px solid #e6b3cc;
background: #f9ecf2;
font-size: 14px;
font-weight: normal;
}
.break__typo{
  background-color: #e6b3cc;
  color: black;
  width: auto;
  padding: 4px 6px;
  border-radius: 4px;

}

.code__schedule{
  background: #C9F8D6;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.code__reschedule{
  background: var(--bg-rescheduled);
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.code_completed {
  background: #ccccc8;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.code_closed{
  background: #dce9fa;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.code__completed{
  background: slategrey;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.code__break{
  background: #e6b3cc;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.sticky-element {
  position: sticky;
  top: 0px;
  overflow: auto; 
  height: 100%; 
  z-index: 990;
}
.back-grey{
  background-color: #ccccc8 !important;
  border: 1px solid black;
}
.back-closed{
  background-color: #dce9fa !important;
  border: 1px solid black;
}

.week-scroll::-webkit-scrollbar {
  width: 6px;

}

.week-scroll::-webkit-scrollbar-track {

  border-radius: 10px;
}

.week-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;


  background: var(--primary-new);
}
.week__event{
  word-break: break-word;
  max-height: 100%;
  min-height: 25px;
  overflow: hidden;
  line-height: normal;
}
.dock-hour-off{
  height: 30px;
  width: 100%;
  border-bottom: 1px solid #bbb;
}
.dock-hour-off-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.po-inbound{
background-color: var(--firstTimeLateBackgroundColour) !important;
}

.code_po_inbound{
  background: var(--firstTimeLateBackgroundColour);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.po-outbound{
  background-color: var(--release-door-in-waiting-dark) !important;
}

.code_po_outbound{
  background: var(--release-door-in-waiting-dark);
  height: 15px;
  width: 15px;
  border-radius: 50%;
}