.eclipseOpsListContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 65vh;
    min-height: 47vh !important;
}

.datePickerBtn {
    padding: 5px 7px 5px 7px;
    margin-left: 0px;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    color: #4E4E4E;
    border: 1px solid gainsboro;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    min-width: 10vw;
}

.appt-summary-ss-datePicker * {
    font-size: 14px;
}

.fw-bold {
    font-weight: 700 !important;
    padding-top: 15px;
}

.actionImg {
    width: 20px;
    height: 20px;
}

.pointer-cursor {
    cursor: pointer;
}