label {
    display: block;
    margin-bottom: 5px;
}
.login-container-ss{
    flex-direction: row-reverse;
}
.inner_blocks {
    font-size: 14px;
}

#bottom-section {
    font-size: 14px;
}

#container {
    min-height: 95vh;
    max-height: 95vh;
}

.inner_blocks {
    position: relative;

    border-radius: 15px;
}

.text-container {
    position: absolute;
    width: 48%;
    height: 100%;
    font-size: 14px;
    top: 0;
    color: white;
    z-index: 2;
    padding: 1.5rem;
    letter-spacing: 1px;
}

.top-para {
    margin-bottom: 3.5rem;
}

#bottom-para {
    margin-bottom: 10px;
    font-size: 14px;
}

#left-block {
    width: 50%;
    position: relative;
}

.login-wrapper {
    background-image: url(../../../../../../Assests/Images/login-bg.png);
    background-repeat: repeat;
    background-size: contain;
    /* background: linear-gradient(180deg, #F5F9FC 40.15%, rgba(245, 249, 252, 0.00) 82.49%); */
}

#bottom-section {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem;
    color: white;
    bottom: 20px;
    right: 0.1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    z-index: 2;
    width: 100%;
}

#bottom-section div {
    color: white;
}

#truck_img {
    width: 100%;
    height: 100%;
}

#truck_img_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

#btn-container {
    display: flex;
    margin-left: 1.5rem;
}

.modal_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #191919;
    margin-inline: 15px;
    padding: 5px 25px 5px 25px;
    border-radius: 5px;
    font-size: 14px;
}

.btn_title {
    margin-left: 0.3rem;
    font-weight: 500;
}

#right-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: palegreen; */
}


#logo-holder {
    flex: 1;
    text-align: center;
}

#logo-holder img {
    width: 300px;
}

.form-section {
    flex: 4;
    width: 60%;
}

.bottom-text-content li {
    color: white;
}

form>h5 {
    font-weight: 600;
}

#login-sub-header {
    color: #94A3B8;
    margin-top: -5px;
    margin-bottom: 30px;
    font-size: small;
}

form {
    width: 60%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
}

/* form > input { */
.input-container {
    width: 100%;
    position: relative;
}

.input-container>input {
    width: 100%;
    height: 3rem;
    padding: 0.6rem;
    border: 0.8px solid rgba(212, 212, 212, 1);
    border-radius: 5px;
    font: small;
    background-color: white;
    margin-top: -20px;
}

.input-container>input ::placeholder {
    color: rgba(212, 212, 212, 1);
    font-size: 12px;
}

form>label {
    font-weight: 500;
    font-size: 16px;
    display: flex;
    place-items: center;
}

#pass-eye {
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgba(16, 198, 245, 1);
}

.form-icon {
    width: 11px;
    height: 11px;
}

.btn-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: orange; */
    width: 100%;
    height: 100%;
}

input[type="checkbox"] {
    border: 0.8px solid rgba(212, 212, 212, 1);
    margin-right: 1px;
    border-radius: 5px;
}

.top-buttons {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.singleBtn {

    width: 60% !important;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    font-size: 14px;

}

.top-buttons button {
    height: 35px;
    padding: 5px;
    background-color: transparent;
    border: none;
    font-size: 0.9em;
}

.btn-submit {

    color: white;
    border-radius: 6px;
    background-image: linear-gradient(to top, #10C6F5, #12BFEB);
    border: 1px solid #0081A1;
    border-image-slice: 1;
    font-weight: 600;
    width: 156px;
    height: 32px;

}

.forgot-pass {
    color: #10C6F5;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 112%;
    margin: 0;
    background: transparent;
    border: none;
    /* 13.44px */
}

#reg-btn {
    width: 100%;
    padding: 0.7rem 3rem 0.7rem 3rem;
    border: none;
    border-radius: 6px;
    background-color: #E9EBED;
}

/* * Truck With text styles */
.contact-handles {
    color: white;
    text-decoration: none;
    font-size: 12px;
}

.bottom-text-content {
    font-size: 15px;
}

.bottom-text-content {
    list-style: none;
}

.bottom-text-content>li {
    margin-left: -30px;
}

#right-triangle {
    width: 8px;
    height: 8px;
    margin-right: 5px;
}

/* Todo : Media Query section */
@media screen and (min-width : 1920px) {
    #right-block>div {
        width: 80%;
    }
}

@media screen and (max-width : 1440px) {
    .bottom-text-content {
        font-size: 13px;
    }

    .top-para {
        margin-bottom: 2.5rem;
    }
}

@media screen and (max-width : 1024px) {
    form {
        padding: 0;
    }

    #left-block {
        flex: 1.2;
    }

    #right-block {
        flex: 1;
    }

    .input-container>input {
        padding: 0.3rem;
        height: 2.5rem;
        font-size: small;
    }

    .modal_btns {
        padding: 5px 5px 5px 5px;
        font-size: small;
        margin-inline: 8px;
    }

    #bottom-section {
        font-size: 13px;
    }

    .top-para {
        font-size: 13px;
        margin-bottom: 2rem;
    }

    .bottom-text-content {
        font-size: 12px;
    }

    #bottom-para {
        font-size: 14px;
    }
}
.passwordTextInput input::-ms-reveal { 
    display: none; 
}

/* Extra Small devices (portrait tablets and large phones, 600px) */
@media only screen and (max-width: 600px){
    #bottom-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
     }
}


/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
.login-container-ss{
    flex-direction: column;
    overflow: auto !important;
}

.form-section {
    width: 100%;
}

.singleBtn{
    width: 100% !important;
}

#left-block {
    width: 100%;
}


}

/*  devices (portrait tablets and large phones, 768px and uptp 1024px) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .form-section{
        width: 90%;
    }
    .singleBtn{
        width: 90% !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}


#container::-webkit-scrollbar {
    width: 6px !important;
  
  }
  
  #container::-webkit-scrollbar-track {
  
    border-radius: 10px !important;
  }
  
  #container::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  
  
    background: var(--primary-new) !important;
  }