.roleListTable{
    overflow-y: scroll !important;
    max-height: 60vh !important;
    margin-top: 0%;
}

.roleListTable th {
    background-color: var(--white) !important;
}


.userListTable{
    overflow-y: scroll !important;
    max-height: 60vh !important;
}