.changePasswordWrapper .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.passwordDiv.changePassword {
    margin-top: 2%;
    width: 90%;
    color: var(--dark) !important;
}

.changePasswordWrapper .subText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
.changePasswordWrapper .passwordInfo{
    background-color: var(--passwordInfo);
}