.my-appintmentsSS-wrapper {
    background: white;
    max-height: 100%;
    height: 100%;
    width: 100%;
    border-radius: 14px;


}

.wrapperMyApptsCarrierSS {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
}

.myappointment-ss-grid-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    min-height: 70vh;
}

.myappointment-ss-grid-wrapper::-webkit-scrollbar {
    width: 6px;
}

.myappointment-ss-grid-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
}

.myappointment-ss-grid-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--poApproveBtn-hover-light);
}

.unselected-button {
    padding: 8px 15px 8px 15px;
    border: none;
    background-color: #F5F9FC;
    font-size: 13px;
    border-radius: 8px;
}

.selected-button {
    padding: 8px 15px 8px 15px;
    border: 1px solid #F5F9FC;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 3px 0px, rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
    font-size: 13px;
    z-index: 99;
}

.date-picker-btn {
    padding: 5px 7px 5px 7px;
    margin-left: 25px;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    color: #4E4E4E;
    border: 1px solid gainsboro;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.search-wrapper {
    position: relative;
    padding-left: 28px;
    border-radius: 5px;
}

.search-wrapper>input {
    outline: none;
}

.search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    color: grey;
}

.myappt-ss-datePicker {
    position: absolute;
    top: 120%;
    z-index: 999;
    background: var(--white);
    border-radius: 8px;
    box-shadow: 0 0 2px var(--black);
}

.myappt-ss-datePicker.f-report {
   z-index: 9999;
}
@media only screen and (max-width: 600px){
    .wrapperMyApptsCarrierSS{
        grid-template-columns: repeat(1, 1fr);
    }
    .my-appt-actions{
        flex-direction: column;
    }
    .my-appt-actions-left{
        justify-content: space-between !important;
    }
    .my-appt-actions-right, .my-appt-actions-right > div,.my-appt-actions-right>div > span,.my-appt-actions-right>div > span>input{
        width: 100% !important;
    }
    .myappt-ss-datePicker{
        right: 0;
    }
    .myappointment-ss-grid-wrapper {
        max-height: 62vh;
        min-height: 62vh;
        padding-bottom: 2rem;
    }

    
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .wrapperMyApptsCarrierSS{
        grid-template-columns: repeat(2, 1fr);
    }
}