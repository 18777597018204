.ssappointmentListTable-bookappt{
    max-height: 45vh !important;
    overflow-y: scroll !important;
}

.ssappointmentListTable-wpflow{
    max-height: 80vh !important;
    overflow-y: scroll !important;
}

.seperator{
    height: 2px;
    width: 100%;
    background-color: --var(black);
}

.colourIndicatorPOLookup{
    border: 0px solid none !important;
    width: 60px !important;
    height: 10px !important;
    margin: 0px 10px;
}