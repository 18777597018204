.carousel {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.cc-carousel-item {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}

.fixed-wrapper {
  position: relative;
}

.carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #617d98;
}

#next_btn,
#prev_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#next_btn,
#prev_btn {
  width: 25px;
  /* height: 50%; */
  border: none;

  font-size: large;
  background-color: transparent;

}

/* #next_btn span,#prev_btn span{
  border-radius: 50%;
  padding: 10px;
  background-color: rgba(0,0,0,0.2);
  color: white;
} */
#next_btn {
  top: 6%;
  right: 28%;
}

#prev_btn {
  top: 6%;
  left: 55%;
}

.cc-indicator {
  position: absolute;
  top: 2.5%;
  left: 30%;
}