.inputTitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.colourIndicatorText{
    border: 0.5px solid var(--dark) !important;
    height: 25px !important;
    font-size: 12px;
    border-radius: 4px;
    width: 175px !important;
    background-color: transparent;
}

.customTextInput {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
}
.customTextArea {
    background-color: var(--white);
    border-radius: 6px;
    height: 60px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
}
.customSelect {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 5px 16px;
    color: black;
}

.paddingSelect{
    padding: 5px 16px;
    color:black;
}
.customError {
    border-color: var(--error);
}
.color-red{
    color: var(--error);  
}
.errorMessage {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--error);
    margin-top: 2%;
}

.passwordTextContianer{
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border: 1px solid var(--dark);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
}

.radioLabels{
    font-size: 12px;
}
.dateTextInput {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
    margin-left:5px;
    width: 150px;
}
.dateTextButton {
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 10px 16px;
    color: var(--header-item-color);
    margin-left:5px;
    width: 150px;
}
.selectedBorderColor{
    border-color: var(--firstTimeLateBackgroundColour);
    border-width: 2px;
}
