.help_and_feedback_left {
    border-radius: 18px 0px 0px 17px;
    background: var(--navigation);
    width: 316px;
    height: inherit;
    padding: 1rem;
}

.help_and_feedback_right {
    width: 50vw;
    padding: 1.5rem;
    height: 75vh;
    font-size: 14px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.help_and_feedback_heading {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 83.333% */
    letter-spacing: 0.09px;
}

.help-form-group-ss {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.f-11 {
    font-size: 11px;
}

.help_and_feedback_list {
    list-style: none;
    padding: 0 0 0 10px;
    margin: 0;
    font-weight: 600;
}

.help_and_feedback_list li {
    margin: 5px 0;
}

.acc-settings-body {
    width: 40vw;
    padding: 3rem 3rem;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acc-settings-img {
    width: 100px;
}

.text-highlighted {
    color: var(--primary-new);
    font-family: Inter;

    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    /* 0% */
    letter-spacing: 0.055px;
}

.acc-settings-key {
    width: 30%;
    color: var(--text-grey);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 100% */
    letter-spacing: 0.075px;
}

.acc-settings-value {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 100% */
    letter-spacing: 0.075px;
}

.acc-settings-passwrod-indicator {
    border-radius: 6px;
    background: var(--navigation);
    margin-top: 5rem;
    width: 217px;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.acc-settings-passwrod-indicator div img {
    width: 15px;
    height: 15px;
}

.acc-settings-form {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    height: 40vh;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .helpAndFeedback-wrapper{
        flex-direction: column;
    }
    .help_and_feedback_left, .help_and_feedback_right{
        width: 100% !important;
        height: auto !important;
    }
    .help-form-group-ss{
        grid-template-columns: repeat(1, 1fr);
    }
    .help_and_feedback_inside{
        flex-direction: column;
    }
    .help_and_feedback_inside_nav{
        width: 80%;
    }

    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) and (max-width: 768px) {
        .help_and_feedback_left, .help_and_feedback_right{
            width: 50% !important;
            height: auto !important;
        }
        .help-form-group-ss{
            grid-template-columns: repeat(1, 1fr);
        }
    
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        .help-form-group-ss{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
    
    }