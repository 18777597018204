.door-header-wrapper.light-mode{
    background-color: var(--table-selected-background-light) !important;
    color: var(--table-header-text);
}
.door-header-wrapper .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.door-header-wrapper .subTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

