.header-dropdown button{
    border: none !important;
    text-decoration: none !important;
}

.header-dropdown .dropdown-menu a:focus {
  background-color: transparent !important;
  color: var(--black);
}

.profile-pic-logo{
    clip-path: circle();
}

.dropdown-item:active{
  background-color: transparent !important;
}

.dropdown-item:focus-visible{
  background-color: transparent !important;
}