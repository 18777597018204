.poFilterHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.chooseFiltersText {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.chooseFiltersIcon{
    margin : -0.30% 0px 0px 0px;

}

.dark-mode{
    color: var(--white);
}

.light-mode{
    color: var(--chooseFiltersTextColor);
}

.clearFilters{
    font-size: 16px;
    font-weight: 400;
}