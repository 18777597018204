.modal-overlay {
    background: rgba(245, 249, 252,0);
    backdrop-filter: blur(4px);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 5rem;
}

.close-btn-modal {
    position: absolute;
    right: 1%;
    top: 1%;
    /* float: right; */
    background: none;
    border: none;

}

.modalSS-body-ss {
    position: relative;
  
    min-height: 300px;
    width: auto;
    height: auto;
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    background: #FFF;
    /* padding: 8px; */
}

i{
    font-family: 'FontAwesome' !important;
    font-style: normal !important;
}
.popout {
    animation: popout 0.1s ease-in-out;
    -webkit-animation: popout 0.1s ease-in-out;
}
@keyframes popout {
    from{transform:scale(0)}
    to{transform:scale(1)}
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .modal-overlay{
        padding: 10px;
    }
    .modalSS-body-ss{
        width: 100%;
        height: auto;
        max-height: 85%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) and (max-width: 1024px) {
        .modal-overlay{
            padding: 10px;
        }
        .modalSS-body-ss{
            width: 100%;
            height: auto;
            max-height: 90%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
    
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
    
    }

    .modalSS-body-ss::-webkit-scrollbar {
        width: 6px !important;
      
      }
      
      .modalSS-body-ss::-webkit-scrollbar-track {
      
        border-radius: 10px !important;
      }
      
      .modalSS-body-ss::-webkit-scrollbar-thumb {
        border-radius: 10px !important;
        background: var(--primary-new) !important;
      }