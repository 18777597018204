.door-card-wrapper{
    height: 75px;
    width: 60px;
    border-radius: 8px;
    background-color: var(--door-card-bg-light) !important;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.door-card-wrapper .doorNumber{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.door-card-wrapper.dark-mode .doorNumber{
    color: var(--table-header-text) !important;
}

.door-card-wrapper .doorStatus{
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    background-color: var(--table-selection-text-light);
    color: var(--white);
    border-radius: 0px 0px 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}


.door-card-wrapper.disabled{
    background-color: var(--load-header-border) !important;
    /* cursor: none !important; */
    pointer-events: none;
}

.door-card-wrapper.disabled .doorStatus{
    background-color: var(--table-header) !important;
    color: var( --table-border-color) !important;
}

.door-card-wrapper.active{
    background-color: var(--door-card-bg-active) !important;
    pointer-events: none;
}

.door-card-wrapper.active .doorStatus{
    background-color: var(--door-card-bg-bottom-active
    ) !important;
}

.doorOption{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: var(--door-option-text-color-light) !important;
    border: none !important;
    background-color: var(--door-card-bg-light) !important;
}

.door-card-wrapper.active .doorOption{
    color: var(--door-option-text-color-light) !important;
    background-color: var(--door-card-bg-active) !important;
    pointer-events: all !important;
}

.door-card-wrapper.disabled .doorOption{
    background-color: var(--load-header-border) !important;
    color: var( --table-border-color) !important;
    cursor: none !important;
}

.door-card-wrapper.inoperable {
    background-color: var(--table-header) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}

.door-card-wrapper.inoperable .doorStatus {
    background-color: var(--load-header-border) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}

.door-card-wrapper.inUse {
    background-color: var(--in-use-door-background) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}
.door-card-wrapper.inUse .doorStatus{
    background-color: var(--in-use-door-label-background) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}

.door-card-wrapper.blocked  {
    background-color: var(--blocked-door-background) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}

.door-card-wrapper.blocked .doorStatus {
    background-color: var(--blocked-door-label-background) !important;
    color: var( --table-header-text) !important;
    cursor: none !important;
}

.door-card-wrapper.selected  {
    background-color: var(--door-card-bg-active) !important;
    color: var(--dark) !important;
    border: 2px solid var(--selected-door-border);
    border-bottom: 1px solid var(--selected-door-border);
    border-radius: 8px;
}

.door-card-wrapper.released{
    background-color: var(--released-door-background-color) !important;
    color: var(--white) !important;
    border: 2px solid var(--released-door-background-color);
    border-bottom: 1px solid var(--released-door-background-color);
    border-radius: 8px;
}

.door-card-wrapper.released .doorOption{
    color: var(--table-header-text) !important;
    background-color: var(--released-door-background-color) !important;
    pointer-events: all !important;
}

.door-card-wrapper .selected .doorStatus {
    background-color: var(--door-card-bg-bottom-active) !important;
    color: var(--white) !important;
    margin-top: 5px;
}

.released-door {
    background-color: var(--load-header-border) !important;
    color: var( --table-border-color) !important;
    cursor: none !important;
    font-size: 10px !important;
}