.carrierApptBtns {
    border: 0px solid var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.carrierApptBtns.rescheduled {
    background: var(--red);
}

.carrierApptBtns.scheduled {
    background: var(--white);
}

.horizontalSeperator.scheduled {
    width: 100%;
    height: 5px;
    background-color: var(--red);

}
.horizontalSeperator.Completed {
    width: 100%;
    height: 5px;
    background-color: var(--black);

}
.horizontalSeperator.Upcoming {
    width: 100%;
    height: 5px;
    background-color: var(--selected-door-border);

}


.horizontalSeperator.rescheduled {
    width: 100%;
    height: 5px;
    background-color: var(--green);
}

.disabledAction{
    background-color: var(--table-header) !important;
    cursor: not-allowed !important;
}

.rescheduledTimeText.upcoming{
    color: var(--selected-door-border);
}

.rescheduledTimeText.past{
    color: var(--black);
}

.actionButtons {
    width: 25px;
    height: 25px;
}

.dateBolder{
    font-size: 20px;
}

.detailsTextCarrier {
    font-size: 12px;
    padding: 2px 8px;
    font-weight: normal;
}

.firstLine{
    font-size: 14px;
}

.dateTitle.past{
    color: var(--black);
}

.dateTitle.upcoming{
    color: var(--selected-door-border);
}

.locClockIcon{
    width: 10px;
    height: 10px;
}
.filter-green{
    filter: invert(26%) sepia(71%) saturate(2152%) hue-rotate(120deg) brightness(96%) contrast(106%);
}
.filter-black{
    filter: invert(0%) sepia(54%) saturate(0%) hue-rotate(146deg) brightness(98%) contrast(100%);
}
.fw-600{
    font-weight: 600;
}