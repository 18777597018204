.widgetsMenuButton{
    color: var(--primary) !important;
    background-color: var(--table-selected-background-light) !important;
    border : 0px solid none;
}

.selectedButtonCustomDashboard.light-mode{
    background-color: var(--primary);
}

.selectedButtonCustomDashboard.dark-mode{
    background-color: transparent;
    border: 1px solid var(--white);
}