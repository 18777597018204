.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label
  .label__segment-percentages {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 110%;
  left: 0px;
  padding: 3px 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--table-selection-text-light);
  margin-top: 10px;
  opacity: 0;
  border-radius: 8px;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  cursor: default;
  border: 8px solid var(--table-empty-text-color);
  color: var(--white);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label:nth-of-type(n+2)
  .label__segment-percentages{
    background-color: var(--table-empty-text-color);
    border-color: var(--funnel-graph-percentages-second-element-border);
}
.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label:nth-of-type(n+3)
  .label__segment-percentages{
    background-color: var(--funnel-graph-hover-section-third-background);
    border-color: var(--funnel-graph-hover-section-third-border);
}
.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label:nth-of-type(n+4)
  .label__segment-percentages{
    background-color: var(--funnel-graph-hover-section-fourth-background);
    border-color: var(--funnel-graph-hover-section-fourth-border);
}

.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label:nth-of-type(n+5)
  .label__segment-percentages{
    background-color: var(--funnel-graph-hover-section-fifth-background);
    border-color: var(--funnel-graph-hover-section-fifth-border);
}


.svg-funnel-js__label{
  color: var(--dark) !important;
  font-size: 10px !important;
}

.funnel{
  margin: 1%;
}