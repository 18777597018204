.btnDisable-ss {
    border-radius: 5px;
    border: 0.8px solid #20DA8C;
    background: #59ED9D;
    padding: 5px 10px;
    color: var(--pure-white);
}

.btnEnable-ss {

    border-radius: 5px;
    border: 0.8px solid #EB3134;
    background: #ED595C;
    padding: 5px 10px;
    color: var(--pure-white);
}