.timerBanner {
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;
}

.timerBanner.driverAcknowlegment {
    background-color: var(--load-header-border);
}

.timerBanner.waitingForDriverAcknowlegment {
    background-color: var(--light-yellow);
}

.timerBanner.driverAcknowleged {
    background-color: var(--green);
}

.timerBanner.driverRejected {
    background-color: var(--red);
}

.timerTitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-left: 2%;
}

.timerTitle.driverAcknowlegment {
    color: var(--table-border-color);
}

.timerTitle.waitingForDriverAcknowlegment {
    color: var(--dark);
}

.timerTitle.driverAcknowleged {
    color: var(--dark);
}

.timerTitle.driverRejected {
    color: var(--white);
}

.timerContainer {
    border-radius: 8px;
    margin-left: 2%;
    padding: 5px;
}

.timerContainer.driverAcknowlegment {
    border-color: var(--table-border-color) !important;
}

.timerContainer.waitingForDriverAcknowlegment {
    border-color: var(--red) !important;
}

.timerLabel {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

.timerLabel.driverAcknowlegment {
    color: var(--table-border-color);
}

.timerLabel.waitingForDriverAcknowlegment {
    color: var(--red);
}

.timerLabel.driverAcknowleged {
    color: var(--dark);
}

.timerLabel.driverRejected {
    color: var(--white);
}

.timerCounter {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 100px;
    text-align: start;
    margin-left: 3%;
}

.timerCounter.driverAcknowlegment {
    color: var(--table-border-color);
}

.timerCounter.waitingForDriverAcknowlegment {
    color: var(--red);
}

.timerCounter.driverAcknowleged {
    color: var(--dark);
}

.timerCounter.driverRejected {
    color: var(--white);
}