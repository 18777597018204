.requestIcons{
    height: 40px;
    width: 40px;
    background-color: var(--table-hover-light);
}

.adjustedText{
    width : 20vw;
}

.reqExpandedImages{
    background-color: var(--table-hover-light);

}

.requestTableContainer{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
}

.requestTableContainer th{
    background-color: var(--white);
}

.serialNumColumn{
    width: 4vh;
}

.nameColumn{
    width: 10vh;
}

.contactNumColumn{
    width: 12vh;
}

.emailColumn{
    width: 25vh;
}

.emailColumn{
    width: 25vh;
}

.createdOnColumn{
    width: 15vh;
}