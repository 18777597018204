.acc-settings-nav {
    border-radius: 18px 0px 0px 17px;
    background: var(--navigation);
    width: 246px;
    height: inherit;
    padding: 1rem;
}

.acc-settings-body {
    width: 100% !important;
    min-width: 40vh;
    padding: 3rem 3rem;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.acc-settings-img {
    width: 100px;
}

.text-highlighted {
    color: var(--primary-new);
    font-family: Inter;

    font-style: normal;
    font-weight: 500;
    line-height: 0px;
    /* 0% */
    letter-spacing: 0.055px;
}

.acc-settings-key {
    width: 30%;
    color: var(--text-grey);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 100% */
    letter-spacing: 0.075px;
}

.acc-settings-value {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 100% */
    letter-spacing: 0.075px;
    text-overflow: ellipsis;
    width: 60%;
}

.acc-settings-passwrod-indicator {
    border-radius: 6px;
    background: var(--navigation);
    margin-top: 5rem;
    width: 217px;
    color: var(--primary-text);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.acc-settings-passwrod-indicator div img {
    width: 15px;
    height: 15px;
}

.acc-settings-form {
    width: 70%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
    height: 40vh;
}

@media only screen and (max-width: 600px) {
   .acc-settings-nav{
    height: auto !important;
    width: 100% !important;
    padding: 10px !important;
   }
   .acc-settings-body {
    width: 100% !important;
    padding: 10px !important;
    height: auto;
}
    .acc-settings-value{
        text-overflow: ellipsis;
        width: 60% !important;
    }
    .acc-settings-wrapper{
        flex-direction: column;
    }
    .acc-settings-key{
        width: 50% !important;
    }
    .acc-settings-security{
        flex-direction: column;
    }
    .acc-settings-form{
        width: 100% !important;
    }
    .acc-settings-passwrod-indicator{
        width: 100% !important;
        gap: 0.5rem;
    }

}