.tutorial-container {
    border-radius: 8px;
    background: #F5F9FC;
    width: 100%;
    min-height: 14vh;
}

.tutorial-heading {
    color: #000;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 138.462% */
    letter-spacing: 0.26px;
}

.tutorial-text {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: 0.24px;
}

.tutorial-sub-text {
    color: #000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    letter-spacing: 0.2px;
}

.w-40 {
    width: 40%;
}

@media only screen and (min-width: 600px) and (max-width:1024px) {
    .tutorial-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}