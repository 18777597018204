.pODockStatusFilter{
    height: auto;
}

.dark-mode{
    color: var(--white);
    background-color: var(--dark);
}

.light-mode{
    color: var(--dark);
    background-color: var(--white);
}

.dark-mode.po-dock-status-filters{
    color: var(--white);
    background-color: var(--dark);
}

.light-mode.po-dock-status-filters{
    color: var(--dark);
    background-color: var(--white);
}

.clearFilters{
    height: auto;
    width: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}