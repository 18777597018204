.pOListFilters{
    height : 5%;
    width : 100%;
}

@media (max-width: 540px) {
    .pOListFilters { 
        margin: 0px;
        padding: 2px;
        display: flexbox !important;
        height: auto;
        flex-direction: column !important;
     }
    
}