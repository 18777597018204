.truckUnloadStatsTable{
    overflow-y: scroll;
    max-height: 65vh !important;
}

.h1TruckUnloadSummary{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}

.h2TruckUnloadSummary{
    height: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}