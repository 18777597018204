.bgContainer {
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
}
.bgContainer > img {
    width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.bgContainer_verfication_screen{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.parentContainer {
    flex: 0.4;
    padding-right: 30px;
    z-index: 1;
}

.loginContainer {
    background-color: rgba(255, 255, 255, 0.9);
    flex: 0.8;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    border-radius: 8px;
}
.verification_loginContainer{
    padding-top: 10px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
}
.whtitle {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    flex: 1;
    text-align: center;
    margin-top: 4%;
    color: var(--black) !important;
}

.emailDiv {
    margin-top: 24px;
    width: 90%;
    color: var(--dark) !important;
}

.passwordDivWrapper{
    width: 100%;
    overflow-y: scroll;
    max-height: 35vh;
}
.verfication_screen_passwordDivWrapper{
    max-height: 55vh !important;
}

.passwordDiv {
    margin-top: 4%;
    width: 90%;
    color: var(--dark) !important;
}

.verfication_passwordDiv
{
    margin-top: 0 !important;
}

.logoContainer {
    width:75%;
}

.logoContainerCarrier {
    width:100%;
}
.rememberMeContainer {
    margin-top: 5%;
    width: 90%;
}

.rememberMeText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--dark) !important;
}

.loginButtonContainer {
    margin-top: 2%;
    width: 90%;
}

.loginButtonContainerSearchPO {
    margin-top: 2%;
    width: 95%;
}

.loginButton {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    padding: 8px 35px;
    margin-top: 8px;
    background-color: var(--primary);
    color: var(--white) !important;
    /* padding: 10px; */
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--primary);
    border-color: transparent;
}

.bottomContainer {
    margin-top: 4%;
    width: 85%;
}

.feedbackButton {
    background-color: transparent;
    border-color: transparent;
    margin-top: 2px;
    margin-right: 5px;
}

.helpButton {
    background-color: transparent;
    border-color: transparent;
}

.forgotPasswordText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--dark) !important;
}

.checkBox {
    width: 20px;
    height: 20px;
    margin-right: 4%;
    border-color: red;
}

/* Remove this code as Devansh already created reusable component for formDropdown */
.formDropdown{
    font-weight: 400;
    font-size: 16px;
    /* width : 100% !important; */
    margin-top: 0px !important;
    /* height: 40px; */
    width :max-content;
    /* border : 1px solid var(--table-header); */
}

.formDropdown.light-mode button{
  border : 1px solid var(--table-header);
  color: var(--black);
}

.formDropdown.dark-mode button{
  border : 1px solid var(--white);
  color: var(--white);
}

.dropdown-menu {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
}


.chooseWarehouse{
  margin-top: 15%;
}
