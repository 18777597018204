.truckUnloadSummaryTable{
    overflow: scroll !important;
    max-height: 65vh !important;
    min-width: 100vw !important;
}

.truckUnloadSummaryTable th{
    background-color: var(--white) !important;
}

.truckUnloadSummaryHeading{
    min-width: 10vw !important;
}

.reportsLogo{
    width: 100px !important;
    height: 75px !important;
    object-fit: contain !important;
}

.reportsInput{
    font-style: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}