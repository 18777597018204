.downloadSection{
    cursor: pointer;
}

.dropDownDownItemloadSection{
    background-color: var(--white);
}

.downloadButton{
    font-weight: 500;
    font-size: 12px !important;
}
.downloadButton .light-mode{
    color: var(--table-hover-dark);
}
.downloadButton .dark-mode{
    color: var(--white);
}
.column-select-checkbox >div>div{
    flex-direction: row-reverse !important;
    justify-content: start;
    row-gap: 5px;
}
.column-select-checkbox input{
    margin-right: 10px !important;
}
