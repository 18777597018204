.allApptsListContainer-ss {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 75vh !important;
    min-height: 75vh !important;
  }
  
  .notScheStatusText {
    color: var(--release-door-in-waiting-hover)
  }
  
  .appointments-report-ss-wrapper {
    background: white !important;
    border-radius: 15px;
    height: 100%;
  }
  
  .appointments-report-ss-wrapper * {
    color: #393939;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 93.75% */
    letter-spacing: 0.08px;
  }
  .table-summary th,td{
    word-break: keep-all;
    width: auto !important;
    padding: 8px;
    border-radius: 4px !important;
  }
  .table-summary td{
    border-top: 1px solid black;
  }
  .table-summary th{
    background-color: var(--navigation);
    z-index: 1 !important;
  }
  .appt-summary-list-ss{
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 72vh !important;
    max-height: 72vh !important;
  }
  