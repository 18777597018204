.display-content-center {
    display: flex !important;
    justify-content: center;
    align-items: center
}

.dataTime-container {
    border-radius: 6px;
    background: var(--navigation);
    padding: 1rem;
    text-align: left;
    color: var(--primary-text);
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: 20px;
}

.success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.success-wrapper * {
    color: var(--primary-text);
    text-align: center;
    font-family: Inter;
    font-style: normal;
    line-height: 20px;
    /* 142.857% */
}

.dateTime-value {

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 200% */
}

.dateTime-key {
    color: #191919;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 200% */
    text-align: left;
}