.statusDescriptionText{
    font-weight: 500;
    font-size: 12px;
}

@media (max-width: 1030px) {
    .statusDescriptionText{
        font-weight: 300;
        font-size: 9px;
    }
    
}