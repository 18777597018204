.container-fluid.dashboard{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 70vh !important;
}
.container-fluid.customDashboard{
    overflow-y: scroll;
    max-height: 58vh !important;
}

.container-fluid.defaultDashboard.light-mode{
    background-color: var(--table-selected-background-light) !important; 
}
.container-fluid.defaultDashboard.dark-mode{
    background-color: var(--black) 
}
.container-fluid.defaultDashboard.light-mode row{
    background-color: var(--white) !important; 
}
.container-fluid.defaultDashboard.dark-mode row{
    background-color: var(--black) 
}

.chart-wrapper{
    /* height: 200px; */
}


.graphHeading{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}

.subText {
    font-style: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.boldText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.bolderText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.navText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.bigText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.calendarDateText{
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
}

.detention-legend .apexcharts-legend{
    /* Restrict the legend to overflow the chart area */
    overflow: hidden;
    top: 0 !important;
  }
.apexcharts-canvas > svg {
    background-color: transparent !important;
}

.collapseicon.light-mode{
    color: var(--black);
    background-color: transparent;
}
.collapseicon.dark-mode{
    color: var(--white);
    background-color: transparent;
}
.customDashboardPlaceholder{
    height: 400px !important;
}
.customDashboardPlaceholder.light-mode{
    background-color: var(--table-selected-background-light) !important;
}
.customDashboardPlaceholder.dark-mode{
    background-color: var(--black) ;
}

.closeSection > span{
    cursor: pointer;
}