.appt-container {
    border-radius: 14px;
    background: #FFF;
    padding: 10px;
    width: 58vw;
    height: 70vh;
    max-height: 70vh;
}

.w-75 {
    width: 75%;
}

.calender-wrapper {
    width: 100%;

}