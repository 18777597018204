@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
    --primary: #106D96;
    --primary-new: #10C6F5;
    --banner-bg: rgb(103, 186, 208);
    --pure-white: #FFFF;
    --white: #F9F9F9;
    --primary-text: #383838;
    --bg-light-grey: #FCFDFD;
    --navigation: #F5F9FC;
    --light-grey: #D4D4D4;
    --text-grey: #848586;
    --black: #000000;
    --dark: #151515;
    --table-hover-light: #D6E0E3;
    --table-hover-dark: #333333;
    --table-selected-background-light: #EEF1F2;
    --table-selected-background-dark: #1F1F1F;
    --table-selection-text-light: #006F9F;
    --table-selection-text-dark: #5ECCE7;
    --table-header: #ADADAD;
    --table-border-color: #575757;
    --table-header-text: #424242;
    --table-empty-text-color: #004C6D;
    --dark-mode-text-color: #4BC8FF;
    --dark-mode-border-color: #4BC8FF;
    --load-header-border: #DBDBDB;
    --header-item-color: #7E7E7E;
    --chooseFiltersTextColor: #575757;
    --poApprovalBgColor-light: #DAE9EF;
    --poApprovalBgColor-dark: #3D494E;
    --poApproveBtn-hover-light: #26A8E0;
    --poRescheduleBtn-hover-light: #E6EEF1;
    --door-card-bg-light: #E3F3FA;
    --door-card-bg-active: #C4EFCB;
    --door-card-bg-bottom-active: #00570E;
    --door-option-text-color-light: #3F7488;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1367px;
    --header-item-color: "#7E7E7E";
    --chooseFiltersTextColor: #575757;
    --error: #E45174;
    --light-yellow: #FFDFAD;
    --green: #89DA96;
    --red: #CC4141;
    --in-use-door-background: #E7D0A6;
    --in-use-door-label-background: #EBE0CB;
    --blocked-door-background: #D1A5A5;
    --blocked-door-label-background: #E6CACA;
    --selected-door-border: #018517;
    --released-door-background-color: #ffea00;
    --released-door-light-background-color: #ffea00;
    --funnel-graph-percentages-second-element-border: #213748;
    --funnel-graph-hover-section-third-background: #5B494D;
    --funnel-graph-hover-section-third-border: #3e383a;
    --funnel-graph-hover-section-fourth-background: #934A3C;
    --funnel-graph-hover-section-fourth-border: #882F20;
    --funnel-graph-hover-section-fifth-background: #B04C35;
    --funnel-graph-hover-section-fifth-border: #882F20;
    --release-door-in-waiting-light: #ff595e;
    --release-door-in-waiting-dark: #d90429;
    --release-door-in-waiting-hover: #f77f00;
    --release-door-in-waiting-selected: #fcbf49;
    --defaultDashboardLightBorder: #EFF1F2;
    --customDashboardPlaceholder: #DEE8EB;
    --sometimesLateBackgroundColour: #FFA600;
    --firstTimeLateBackgroundColour: #0073A5;
    --modalColor: rgba(49, 49, 49, 0.8);
    --passwordInfo: #E1EDF2;
}

*,
/* ::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
} */
html {
    font-family: "Poppins";
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    /* height: 100%; */
}

section {
    display: block;
}

body {
    width: 100%;
    height: calc(100vh);
    overflow: hidden;
    margin: 0;
    padding: 0;
    top: 0;
    bottom: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 20px;
    background-color: var(--white);
}
.pointer-event-none{
    pointer-events: none;
}

.pointer-event-none.light-mode {
    pointer-events: none;
    background-color: var(--load-header-border) !important;

}

.pointer-event-none.dark-mode {
    pointer-events: none;
    background-color: var(--load-header-border) !important;

}

.disabled {
    /* cursor: none !important; */
    cursor: not-allowed !important;
}

.pointer {
    cursor: pointer;
}

.boxShadow-1 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.h-100 {
    height: 100%;
}

.min-h-42 {
    min-height: 42vh;
}

.min-h-32 {
    min-height: 32vh;
}

.btn-main {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    padding: 8px 8px;
    width: 110px;
    margin-top: 4px;
    background-color: var(--primary);
    color: var(--white) !important;
    /* padding: 10px; */
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--primary);
    border-color: transparent;
}

.btn-main-recom {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    padding: 8px 8px;
    width: 110px;
    margin-top: 4px;
    background-color: var(--table-hover-light);
    color: var(--black) !important;
    /* padding: 10px; */
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--header-item-color);
    border-color: transparent;
}

.btn-main-new {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    padding: 8px 8px;
    margin-top: 4px;
    background-color: var(--primary);
    color: var(--white) !important;
    /* padding: 10px; */
    border-radius: 8px;
    box-shadow: 1px 1px 5px var(--primary);
    border-color: transparent;
}

.btn-main-selected {
    background-color: var(--primary);
    color: var(--white) !important;
    transform: scale(1.1);
}

.hidden {
    display: none;
}

.pos-rel {
    position: relative;
}

.recommend-submit-btn {
    position: fixed;
    bottom: 5rem;
    right: 3rem;
}

.overflow-y {
    overflow-y: scroll;
}

.recomended-dates {
    max-height: 24vh;
    padding: 0 5px;
}

.d-grid {
    display: grid;
}

.grid-3 {
    grid-template-columns: auto auto auto;
}

.grid-2 {
    grid-template-columns: auto auto;
}

.f-16 {
    font-size: 16px !important;
}

.f-14 {
    font-size: 14px !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.row-gap-1 {
    row-gap: 1rem;
}

.h-25 {
    height: 25vh;
}

.min-h-20 {
    min-height: 20vh;
}

.max-h-19 {
    max-height: 19vh;
}

.schedule-manual {
    overflow-y: scroll;
    max-height: 42vh;
    overflow-x: hidden;
}

.w-inherit {
    width: inherit;
}

.custom-tooltip {
    z-index: 9999 !important;
}

.textRed {
    color: var(--red);
}

.bgRed {
    background-color: #E39898  !important;
}


.textGreen {
    color: var(--selected-door-border);
}

.accordion-child td {
    background-color: rgb(211, 211, 211);
}

.w-100px {
    width: 100px;
}


.hidden {
    display: none;
}

.w-full {
    width: -webkit-fill-available;
}

.w-200 {
    width: 200%;
}
.w-30 {
    width: 30%;
}
.w-35 {
    width: 35%;
}

.overflow-hidden {
    overflow: hidden;
}

.min-h-90 {
    min-height: 90vh;
}

.min-width-10{
    min-width: 10%;
}

.btn-highlighted {
    color: white !important;
    border-radius: 6px;
    background: linear-gradient(180deg, #10C6F5 27.94%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid #0081A1;
    font-weight: 600;
    padding: 6px 20px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-white {
    border-radius: 5px;
    border: 0.5px solid #E5E5E5;
    background: var(--pure-white);
    color: var(--primary-text);
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
    font-weight: 600;
    padding: 6px 20px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-highlight {
    color: #0081A1;
}

.color-black {
    color: var(--black) !important;
}

.color-white {
    color: var(--pure-white) !important;
}

.text-grey {
    color: var(--text-grey) !important;
}

.text-grey-bg {
    background-color: #ccccc8 !important;
    color: black;
}

.wh-closed {
    background-color: #dce9fa !important;
    color: black;
}

.text-style-no {
    text-decoration: none;
}



/* Toast CSS New UI START*/
.Toastify__toast-icon,
.Toastify--animate-icon,
.Toastify__zoom-enter {
    display: none;
}

.Toastify__toast {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 5px;
    border-radius: 10px;
}

.Toastify__toast img {
    height: 32px;
    width: 32px;
}

.Toastify__toast--success {
    border: 1.4px solid #B5E3BB;
    background: #F2FFF7;
}

.Toastify__toast--warning {
    border: 1.4px solid #FCD68E;
    background: #FEF8EB;
}

.Toastify__toast--error {
    border: 1.4px solid #FCBAA4;
    background: #FAEFEB;
}

.Toastify__toast--info,
.Toastify__toast--default {
    border: 1.4px solid #B3CBEE;
    background: #E7EFFA;
}

/* Toast CSS New UI END*/

.btn-action {
    color: white !important;
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 27.94%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid #0081A1;
    font-weight: 600;
    padding: 6px ;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.f-18{
    font-size: 18px !important;
}
.line-gap-2{
    line-height: 1.5;
}
.w-80vw{
    width: 80vw;
}
.word-break{
    word-break: break-all;
}
.custom-form-input-highlighted input{
    box-shadow: 0 0 5px var(--firstTimeLateBackgroundColour);
}
.w-200px{
    width: 200px;
}
.downlaod-popup>div>div{
    margin: 0;
}
.checkbox-group *{
    font-size: 14px;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.f-wrap{
    flex-wrap: wrap;
}
.color-green{
    color: var(--selected-door-border);
}
.download-seconday-btn{
    border-radius: 8px;
    border: 1px solid;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 500;
}
.btn-outlined{
    border: 1px solid var(--primary-text);
    padding: 8px 16px;
    color: var(--primary-text) !important;
    height: auto !important;
    border-radius: 4px !important;
}

.btn-danger{
    border-radius: 4px;
    background: linear-gradient(180deg, var(--red) 0%, var(--red) 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid var(--red);
    padding: 8px 16px;
    color: var(--pure-white) !important;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.w-max{
    width: max-content;
}
.fw-400{
    font-weight: 400;
}
.event-none{
    pointer-events: none !important;
}
.w-40{
    width: 40% !important;
}
.w-60{
    width: 60% !important;
}
.no-visible{
    visibility:hidden;
}

.disabled-btn {
    color: black;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 12px;
    border-radius: 4px;
    background: linear-gradient(180deg, #D4D4D4, 0%, #D4D4D4 100%);
    border: none;
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    cursor: not-allowed !important;
}
.common-hover:hover{
    cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .btn-highlighted{
        width: 100% !important;
    }
    .no-data-wrappper-ss > div{
        width: 150vw;
    }
    
    }
    

    @media screen and (orientation: portrait) and (max-width: 1024px) {
        .App {
          background-color: #fff;
        }
        .landscape-msg{
            display: none;
        }
      }
      
      @media screen and (orientation: landscape) and (max-width: 1024px) {
    
        .App {
          display: none; 
        }
        .landscape-msg{
            display: flex;
            width: 100vw;
            height: 100vh;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 600;
        }
      }
      /* @media screen and (min-width: 769px) and (max-width: 1024px) {
    
        .App {
            background-color: #fff;
          }
          .landscape-msg{
              display: none;
          }
      } */