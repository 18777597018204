.backButton {
    height: 30px;
    width: 30px;
}


.roleFeaturesTable {
    overflow-y: auto;
    max-height: 70vh;
    overflow-x: hidden;
}

.roleFeaturesTable th {
    position: sticky;
    top: 0;
    z-index: 99;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    border-bottom: 1px solid var(--load-header-border);
    /* height: 2%; */
    border-bottom: 1px solid var(--load-header-border);
    background-color: var(--white) ;
}