.spuserListTable{
    overflow-y: scroll !important;
    max-height: 75vh !important;
}


.sp-btn-highlighted {
    color: white !important;
    border-radius: 6px;
    background: linear-gradient(180deg, #10C6F5 27.94%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid #0081A1;
    font-weight: 600;
    padding: 2px 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-icon-wrapper > span{
    width: 25px !important;
    height: 25px !important;
}

@media only screen and (max-width: 600px) {
.sp-user-header{
    flex-wrap: wrap;
    gap: 1rem;
}
}

@media only screen and (max-width: 1024px) {
.userListTable{
    overflow-x: auto;
    max-height: none !important;
}
}