.carrierApptBtn {
    border: 0px solid var(--white);
    padding: 8px 16px;

    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.carrierApptBtn.rescheduled {
    background: var(--bs-orange) !important;
}

.text-red {
    color: var(--bs-red);
}

.text-16 {
    font-size: 16px;
}

.carrierApptBtn.scheduled {
    background: var(--selected-door-border) !important;
}

.horizontalSeperator.scheduled {
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--selected-door-border) !important;
}

.horizontalSeperator.rescheduled {
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--release-door-in-waiting-hover) !important;
}

.horizontalSeperator.completed {
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--black) !important;
}

.horizontalSeperator.apptScheduled {
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--selected-door-border) !important;
}

.horizontalSeperator.apptNotScheduled {
    height: 10px !important;
    margin-right: 15px;
    background-color: var(--red) !important;
}

.carrierapplist-container {
    height: 81%;
}

.multiPO:hover {
    cursor: pointer;
}