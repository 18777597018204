.appointmentListTable {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    min-height: 65vh !important;
    max-height: 65vh !important;
}

.seperator {
    height: 2px;
    width: 100%;
    background-color: --var(black);
}

.btnAction {
    border: 0px solid var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
}

.btnEdit {
    background: var(--table-selection-text-light);
}

.btnDelete {
    background: var(--red);
}

.paginationSection {}

.btnEnable {
    background-color: var(--selected-door-border);
}

.d-nav-active {
    border-bottom: 4px solid var(--dark-mode-border-color);
}

.notScheStatusText {
    color: var(--release-door-in-waiting-hover)
}

.editIconPO {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
}

.editIconPO-ss {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
}

.editIconPO.disabled {
    cursor: not-allowed;
}

.appt-status-block-ss {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
}

.edit-disable {
    opacity: 0.3;
}