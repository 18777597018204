.svg-funnel-js {
    display: inline-block;
    position: relative;
  }
  .svg-funnel-js svg {
    display: block;
  }
  .svg-funnel-js .svg-funnel-js__labels {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  