.customDropdown {
    border: 1px solid var(--table-border-color);
    border-radius: 4px;
}

.dropDownPlaceholder {
    font-weight: 400;
    font-size: 16px;
}

.dropDownPlaceholder.light-mode {
    background-color: transparent !important;
    color: var(--header-item-color);
}

.dropDownPlaceholder.dark-mode {
    background-color: var(--dark);
    color: var(--white)
}
