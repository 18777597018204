.ssappointmentListTable-bookappt {
    max-height: 45vh !important;
    overflow-y: scroll !important;
}

.ssappointmentListTable-wpflow {
    overflow-x: hidden;
    max-height: 80vh !important;
    overflow-y: hidden !important;
}

.appointmentdatelist {
    overflow-y: hidden !important;
    overflow-x: hidden;
    /* max-height: 42vh; */
}

.text-red {
    color: var(--bs-red);
}

.seperator {
    height: 2px;
    width: 100%;
    background-color: --var(black);
}

.colourIndicatorPOLookup {
    border: 0px solid none !important;
    width: 40px !important;
    height: 10px !important;
    margin: 0px 10px;
}

  .plusbtn {
    width: 47px;
    height: 47px;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
  }

  .appointmentStatusLable {
     font-size: 12px;
  }
 