.allSubsListContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 50vh !important;
}

.notScheStatusText {
    color: var(--release-door-in-waiting-hover)
}

.f-report-summary{}