.searchWarehouseInput{
    border : 2px solid var(--table-header);
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
}

input:focus-visible {
    outline: none;
    border-radius: none;
}

.cancelSearchIcon{
    cursor: pointer;
}
