.load-details-wrapper{
    height: 35%;
    border-bottom:  1px solid var(--load-header-border);
}
.load-details-container{
   
}
.load-details-wrapper .load-details-title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.load-details-wrapper .load-details-value {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.load-details-value.driver-name{
    max-width: 80%;
}