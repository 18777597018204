.searchPOTextInput{
    border : 2px solid var(--table-header);
    font-weight: 400;
    font-size: 16px;
    border-left: 0px;
}

.searchPoInput{
    border: 0px;
    background-color: transparent;
    width: 80%;
    margin-right: 10px;
    outline: 0px;
}

.searchPoInput.light-mode{
    color: var(--dark);
    
}

.searchPoInput.dark-mode{
    color: var(--white);
}

@media (max-width: 1093px) {
    .searchPoInput{
        width: 70%;
    }
}

@media (max-width: 825px) {
    .searchPOIcon{
        width: 0px;
        height: 0px;
    }
    .searchPoInput{
        width: 100%;
        font-size: 12px;
        margin: 0px;
        padding : 0px;
    }
}