.inputTitle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.passwordTextInput {
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    height: 39px;
    border-color: var(--dark);
    border-width: 1px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 4px;
    padding: 16px;
    color: var(--header-item-color);
    width: 100%;
}

.passwordTextInput.passwordTextInputError {
    border-color: var(--error);
}

.eyeimage {
    position: relative;
    top: -38px;
    float: right;
    right: 10px;
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
}

.errorMessage {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--error);
    margin-top: 2%;
}

.passwordContainer {
    height: 42px;
}