.scedule-pro-wrapper {
    background: #F5F9FC !important;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    height: 100vh;
    margin: 0;
    padding: 10px;
}

.scedule-pro-wrapper>* {
    color: #383838;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}


.scedule-pro-wrapper::-webkit-scrollbar {
    width: 6px !important;
  
  }
  
  .scedule-pro-wrapper::-webkit-scrollbar-track {
  
    border-radius: 10px !important;
  }
  
  .scedule-pro-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
  
  
    background: var(--primary-new) !important;
  }

@media only screen and (max-width: 1024px) {
    .scedule-pro-wrapper{
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        height: 85vh !important;
    }
    .schedule-pro-body{
        width: 100% !important;
      
    }
    }