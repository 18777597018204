.warehouseIcon{
    width : 100%;
    height : 7vh;
}
.proceedButton{
    cursor: pointer;
    background-color: var(--primary);
    color: var(--white);
    border-radius: 12px;
}

.warehouseCardText{
    font-size: 14px;
}