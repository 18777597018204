.react-datepicker-popper {
    z-index: 9999 !important;
}

.datepicker {
    font-size: 0.8rem;
    border: 1px;
    border-radius: 0;
    display: inline-block;
    position: relative;
    background-color: var(--white);
    color: var(--dark);
    border-radius: 8px;
}

.datepicker.dark-mode {
    background-color: var(--dark);
    box-shadow: 2px 2px 5px var(--table-selected-background-dark);
}

.datepicker.light-mode {
    background-color: var(--white);
    box-shadow: 2px 2px 5px var(--table-selected-background-light);
}

.day-Parent.light-mode.react-datepicker__day--selected {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}

.day-Parent.dark-mode.react-datepicker__day--selected {
    background-color: var(--primary) !important;
    color: var(--white) !important;
}

.day-Parent.light-mode:hover {
    background-color: var(--table-selected-background-light) !important;
}

.day-Parent.dark-mode:hover {
    background-color: var(--table-selected-background-dark) !important;
}

.datepicker.light-mode .react-datepicker__day-name {
    color: var(--dark) !important;
}

.datepicker.dark-mode .react-datepicker__day-name {
    color: var(--white) !important;
}

.datepicker.light-mode .react-datepicker__header {
    background-color: var(--table-header) !important;
    color: var(--dark) !important;
}

.datepicker.dark-mode .react-datepicker__header {
    background-color: var(--table-selected-background-dark) !important;
    color: var(--white) !important;
}

.datepicker.dark-mode .react-datepicker__current-month {
    color: var(--white) !important;
}

.datepicker.light-mode .react-datepicker__current-month {
    color: var(--dark) !important;
}

.customInput {
    border: 0;
    background-color: transparent;
}

.react-datepicker-wrapper {
    width: auto !important;
}

.plainDate {
    position: absolute;
    right: 15%;
    left: 15%;
    bottom: 28%;
    top: 32%;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    text-align: center;
}

.plainDate.light-mode {
    color: var(--header-item-color);
    background-color: transparent !important;
}

.plainDate.dark-mode {
    color: var(--white);
    background-color: transparent !important;
}

.dayText {
    margin-right: 10pt;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 3pt;
}

.dayText.light-mode {
    color: var(--header-item-color);
}

.dayText.dark-mode {
    color: var(--white);
}

.calenderContainer {
    position: relative;
}


.react-datepicker__day--keyboard-selected :not(react-datepicker__day--selected){
    background-color: transparent !important;
    color: var(--black) !important;
}
