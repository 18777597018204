.renew-subs-ss-page {
    overflow-y: auto !important;
    background: #F5F9FC !important;
    max-height: 100vh;
}

.renew-subs-ss-page * {
    color: #383838;
    font-family: Inter;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.navbar-btns-inactive {
    color: var(--black) !important;
    border-radius: 6px;
    background: transparent;
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid var(--black);
    font-weight: 600;
    padding: 8px 20px;
}