.searchPODropdown{
    border : 2px solid var(--table-header);
    font-weight: 400;
    font-size: 16px;
    width : 40%;
}

.dark-mode-searchDropDown{
    color: var(--white);
}

.light-mode-searchDropDown{
    color: var(--dark);
}

@media (max-width: 825px) {
    .searchPODropdown{
        font-size: 12px;
        width : 40%;
        padding: 0px;
        margin: 0px;
        overflow: hidden;
    }

    .dropdownIcon{
        width : 0px;
        height : 0px;
    }
}