.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value .light-mode {
  font-size: 18px;
  color: var(--dark) !important;
  font-weight: 700;
  line-height: 18px;
  background-color: transparent !important;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
  background-color: transparent !important;
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left: 1px solid var(--dark);
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child).light-mode {
  border-color: var(--dark);
}

.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child).dark-mode {
  border-color: var(--white);
}

.label__value {
  font-size: 12px !important;
  background-color: transparent !important;
}

.label__value.light-mode {
  color: var(--dark) !important;
}

.label__value.dark-mode {
  color: var(--white) !important;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 12px !important;
  background-color: transparent !important;
  margin-top: 10px;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title.light-mode {
  color: var(--dark) !important;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title.dark-mode {
  color: var(--white) !important;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__subtitle {
  font-size: 12px;
  margin-top: 27vh !important;
  background-color: transparent !important;
  color: var(--dark);
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__subtitle.light-mode {
  color: var(--dark);
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__subtitle.dark-mode {
  color: var(--white);
}

.svg-funnel-js__container {
  margin-top: 0px !important;
}

.svg-funnel-js {
  margin-top: -10px !important;
}