.avgWrapper.light-mode{
    background-color: var(--white);
}
.avgWrapper.dark-mode{
    background-color: var(--dark);
}
.avgSubWrapper{
gap: 8px;
border-radius: 8px;
}
.avgSubWrapper.light-mode{
    background-color: var(--white);
    border: 1px solid var(--load-header-border);
}
.avgSubWrapper.dark-mode{
    background-color: var(--table-selected-background-dark);
}
.avgText{
    font-weight: 400;
font-size: 12px;
line-height: 18px;
}
.avgNumber{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}
.avgUnit{
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
}