
.multi-select-dd{
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    position: absolute;
    top: 5vh;
    right: 0;
    width: max-content;
    padding: 8px;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* background-color: white; */
}
.multi-select-dropdown{
    width: max-content;
    height: 32px;
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    padding: 8px;
    min-width: 150px;
    z-index: 999;
    position: relative;
    padding-right: 30px;
    cursor: pointer;
}
.multi-select-option{
    width: auto;
    padding: 5px;
    border-radius: 3px;
}
.multi-select-option:hover{
    background-color: var(--navigation) ;
}
.arrow-container-dd{
    position: absolute;
    right: 10px;
    top: 10px;
}
.multi-select-dropdown {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }