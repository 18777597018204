.page-item{
    border-radius: 4px;
background: none;
width: 20px;
height: 20px;
list-style: none;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
margin: 0 6px;
}
.page-active{
    border-radius: 4px;
    background: #dadfde;
    width: 20px;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.pagination-btn-ss{
    width: 20px;
height: 20px;
}

.pagination-btn-ss-text{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 20px;
    gap: 0.5rem;
}
.page-list{
    margin: 0;
    padding: 0;
}
.pagesize-dropdown{
    padding: 4px 6px;
    border-radius: 4px;
}