.poScheduled-wrapper {
    border: 1px solid var(--load-header-border);
    border-radius: 8px;
}
.poHeading-wrapper{
    border-radius: 8px 0 0 8px;
}
.poHeading-wrapper.light-mode{
    background-color: var(--poApprovalBgColor-light) !important;
}

.poHeading-wrapper.dark-mode{
    background-color: var(--poApprovalBgColor-dark) !important;
}

.poScheduled-wrapper .heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.poScheduled-wrapper .subText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

@media (max-width: 767px) {
    .poHeading-wrapper{
        border-radius: 8px 8px 0 0;
    }
}