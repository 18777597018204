.allApptsListContainer{
    overflow-y: scroll;
  overflow-x: hidden;
  max-height: 70vh !important;
}

.notScheStatusText{
  color: var(--release-door-in-waiting-hover)
}
.driver-arrival-list{
  max-height: 62vh;
  overflow-y: auto;
}