.marrt-10{
    margin-right: 10px;
}
.marbt-10{
    margin-bottom: 20px;
}
.react-tel-input .form-control{
    width: 100% !important;
}

