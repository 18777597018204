.downloadSection {
    cursor: pointer;
}

.dropDownDownItemloadSection {
    background-color: var(--white);
}

.downloadButton-ss {
    font-weight: 500;
    font-size: 12px !important;
}

.downloadButton-ss .light-mode {
    color: var(--table-hover-dark);
    background: #FFF;
}

.downloadButton-ss .dark-mode {
    color: var(--white);
}

.download-section-ss {
    width: 120px;
    height: 32px;
    border-radius: 5px;
    border: 0.5px solid #E5E5E5;
    background: #FFF;
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;
}