:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
}

/* GENERAL */

* {
    box-sizing: border-box;
}

.week-calendar {
    width: 100%;
    background: white;

    border-radius: 6px;
}

.week-picker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    font-weight: 700;
    margin-bottom: 1.2rem;
    border-radius: 4px;
    background: #F2F6F5;
}

.week-days {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.week-cal-data {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cal-data-wrapper {
    width: 100%;
}

.week-day {

    padding: 5px;
    border-radius: 6px;
    color: black;
    min-width: 35px;
    cursor: pointer;
}

.week-day:hover {
    border: 0.4px solid #4fa2b4;
    background: #3dbed5;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.2) inset;
    padding: 5px;
    color: white;
}

.week-day-active,
.week-day-selected {
    border-radius: 6px;
    border: 0.4px solid #4fa2b4;
    background: #3dbed5;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.2) inset;
    padding: 5px;
    color: white;
    min-width: 35px;
    cursor: pointer;
}

.week-day-name {
    font-size: 10px;
    text-align: center;
}

.week-date-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
}

.week-cal-navigation {
    background: white;
    outline: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    box-shadow: 0px 0px 2px black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.week-day-number {
    font-weight: 700;
    text-align: center;
}