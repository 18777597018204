.customDropdown-ss {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;

    height: 32px;
    display: flex;
    padding: 0 10px;
    align-items: center;
}

.customDropdown-ss * {
    color: var(--primary-text);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.dropDownPlaceholder {
    font-weight: 400;
    font-size: 12px;
}

.dropDownPlaceholder.light-mode {
    background-color: transparent !important;
    color: var(--header-item-color);
}

.dropDownPlaceholder.dark-mode {
    background-color: var(--dark);
    color: var(--white)
}