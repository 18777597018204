.my-modal-content,
.my-modal-header,
.my-modal-footer {
    border: none !important;
    color: #383838;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 93.75% */
    letter-spacing: 0.08px;
}

.modal-dialog.light-mode,
.modal-dialog.dark-mode {
    background-color: transparent !important;
}

.modal-dialog.light-mode .modal-header,
.modal-dialog.dark-mode .modal-header {
    background-color: var(--table-selection-text-light);
    color: var(--white);

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}


.modal-dialog.light-mode .modal-body {
    color: var(--dark);

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    overflow-y: auto !important;
    color: #383838;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 93.75% */
    letter-spacing: 0.08px;
}

.modal-dialog.dark-mode .modal-body {
    background-color: var(--dark);
    color: var(--white);
    color: #383838;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 93.75% */
    letter-spacing: 0.08px;
}

.modal-dialog.dark-mode .modal-footer {
    border: none;
    background-color: var(--dark);
}

.noScrolling {
    overflow-y: hidden !important;
    height: 90vh !important;
}

.allowScrolling {
    overflow-y: auto !important;
    height: 50vh !important;
    overflow-x: hidden;
}

.createUserPopup {
    overflow-y: auto !important;
    height: 70vh !important;
    overflow-x: hidden;
}

.my-modal-header-ss {
    border-radius: 8px;
    background: #D8F2F7;
    color: #383838;
    padding: 10px !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}

.my-modal-content {
    padding: 5px !important;
}

.color-black {
    color: black !important;
}

.btn-popup-ss-btn-1 {
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    padding: 5px 10px;
    color: var(--pure-white) !important;
    min-width: 120px;
    min-height: 32px;
    border: none;
    font-size: 14px;
}

.btn-popup-ss-btn-2 {
    border-radius: 4px;
    background: none;
    box-shadow: none;
    padding: 5px 10px;
    color: var(--black) !important;
    min-width: 120px;
    min-height: 32px;
    outline: none;
    border: none;
    font-size: 14px;
}

.modal-body-ss {
    color: #383838;

    font-family: Inter !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 93.75% */
    letter-spacing: 0.08px;
}

.subText-popup-ss {
    font-family: Inter !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.08px;
}

.btn-popup-ss-btn-secondary {
    box-shadow: 0 0 2px black;
    border-radius: 4px;
    background: none;

    padding: 5px 10px;
    color: black;
    min-width: 120px;
    min-height: 30px;
    outline: none;
    border: none;
    font-size: 14px;
}