.settings-wrapper{
    overflow-y: scroll;
    max-height: 80vh !important;
}
.container-fluid.settings-wrapper.light-mode{
    background-color: var(--table-selected-background-light) !important; 
}
.container-fluid.settings-wrapper.dark-mode{
    background-color: var(--black) 
}
.user-info-wrapper.light-mode{
    background-color: var(--white) !important; 
}
.container-fluid.user-password-wrapper.dark-mode row{
    background-color: var(--black) 
}
