.adafatreport {
    overflow-y: scroll !important;
    max-height: 60vh !important;
}

.adafatreport table.adfatReportWrapper{
    border-collapse: collapse !important;
}

.adafatreport table.adfatReportWrapper thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.adafatreport table.adfatReportWrapper thead tr th {
    text-align: center;
    padding: 5px !important;
}

.adafatreport table.adfatReportWrapper thead tr td {
    text-align: center;
    padding: 5px !important;
}

.adafatreport table.adfatReportWrapper thead tr:nth-child(1) th:nth-child(1),
.adafatreport table.adfatReportWrapper thead tr:nth-child(1) th:nth-child(2),
.adafatreport table.adfatReportWrapper thead tr:nth-child(1) th:nth-child(3),
.adafatreport table.adfatReportWrapper thead tr:nth-child(1) th:nth-child(4),
.adafatreport table.adfatReportWrapper thead tr:nth-child(1) th:nth-child(5) {
    border: 1px solid var(--table-header) !important;
}

.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(1) {
    border-left: 1px solid var(--table-header) !important;
}

.adafatreport table.adfatReportWrapper thead tr:nth-child(1) {
    border-bottom: 1px solid var(--table-header) !important;
}
.adafatreport table.adfatReportWrapper thead tr:nth-child(2) {
    border-top: 1px solid var(--table-header) !important;
    border-bottom: 1px solid var(--table-header) !important;
}

.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(3),
.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(6),
.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(9),
.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(16),
.adafatreport table.adfatReportWrapper thead tr:nth-child(2) th:nth-child(20) {
    border-right: 1px solid var(--table-header) !important;
}

.adafatreport table.adfatReportWrapper td:nth-child(1) {
    border-left: 1px solid var(--table-header) !important;
    padding: 5px;
}

.adafatreport table.adfatReportWrapper td:nth-child(3),
.adafatreport table.adfatReportWrapper td:nth-child(6),
.adafatreport table.adfatReportWrapper td:nth-child(9),
.adafatreport table.adfatReportWrapper td:nth-child(16),
.adafatreport table.adfatReportWrapper td:nth-child(20) {
    border-right: 1px solid var(--table-header) !important;
}

.adafatreport table.adfatReportWrapper tbody tr:last-child {
    border-bottom: 1px solid var(--table-header) !important;
}


.adafatreport tr:nth-child(1) th:nth-child(1),
.adafatreport tr:nth-child(2) td:nth-child(1) {
    width: 33.33%;
}

.adafatreport tr:nth-child(1) th:nth-child(2),
.adafatreport tr:nth-child(2) td:nth-child(2),
.adafatreport tr:nth-child(2) td:nth-child(3),
.adafatreport tr:nth-child(1) th:nth-child(3),
.adafatreport tr:nth-child(2) td:nth-child(4),
.adafatreport tr:nth-child(2) td:nth-child(5),
.adafatreport tr:nth-child(2) td:nth-child(6) {
    width: 16.67%;
}

.adafatreport tr:nth-child(1) th:nth-child(4),
.adafatreport tr:nth-child(2) td:nth-child(7),
.adafatreport tr:nth-child(2) td:nth-child(8),
.adafatreport tr:nth-child(2) td:nth-child(9),
.adafatreport tr:nth-child(2) td:nth-child(10),
.adafatreport tr:nth-child(2) td:nth-child(11),
.adafatreport tr:nth-child(2) td:nth-child(12),
.adafatreport tr:nth-child(2) td:nth-child(13),
.adafatreport tr:nth-child(2) td:nth-child(14),
.adafatreport tr:nth-child(2) td:nth-child(15),
.adafatreport tr:nth-child(2) td:nth-child(16) {
    width: 58.33%;
}

.adafatreport tr:nth-child(1) th:nth-child(5),
.adafatreport tr:nth-child(2) td:nth-child(17),
.adafatreport tr:nth-child(2) td:nth-child(18),
.adafatreport tr:nth-child(2) td:nth-child(19),
.adafatreport tr:nth-child(2) td:nth-child(20),
.adafatreport tr:nth-child(2) td:nth-child(21) {
    width: 25%;
}