.custom-select {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    width: 100%;
    height: 40px !important;
}

.btn-unstyled {
    color: black;
    border-radius: 6px;
    background: none;
    border: 1px solid transparent;
    font-weight: 600;
    min-width: 100px;
    height: 32px;
}

.btn-styled {
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    border: 1px solid #10C6F5;
    padding: 8px 16px;
    color: var(--pure-white) !important;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}