.allApptsListContainer-ss {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh !important;
  min-height: 75vh !important;
}

.notScheStatusText {
  color: var(--release-door-in-waiting-hover)
}

.appointments-report-ss-wrapper {
  background: white !important;
  border-radius: 15px;
  height: 100%;
}

.appointments-report-ss-wrapper * {
  color: #393939;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  /* 93.75% */
  letter-spacing: 0.08px;
}
.table-summary th,td{
  word-break: keep-all;
  width: auto !important;
  padding: 8px;
  border-radius: 4px !important;
}
.table-summary td{
  border-top: 1px solid black;
}
.table-summary th{
  background-color: var(--navigation);
  z-index: 1 !important;
}
.appt-summary-list-ss{
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 72vh !important;
  max-height: 72vh !important;
}

.appt-summary-ss-datePicker {
  position: absolute;
  top: 120%;
  right: 0;
  z-index: 999;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 2px var(--black);
  width: fit-content;
}

@media only screen and (max-width: 700px) {
  .all-appt-list-action-right{
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    gap: 1rem;
  }
  /* .appointments-report-ss-wrapper>div{
    flex-direction: column;
    gap: 1rem;
    align-items: start !important;
  } */
  .appt-report-header{
    flex-wrap: wrap;
    gap: 1rem;
  }
  .appt-report-headers-full{
    margin: 0 !important;
  }
  .appt-report-graph{
    flex-direction: column-reverse;
  }
  .appointments-report-ss-wrapper > div{
    flex-direction: column;
    align-items: start !important;
    gap: 1rem !important;
  }
  .appointments-report-ss-wrapper{
    margin-bottom: 3rem;
  }

}

@media only screen and (max-width: 768px) {
  .appt-report-headers-full{
    flex-direction: column;
    align-items: start !important;
  }
  .appt-summary-ss-datePicker{
    left: 0 !important;
  }
}

@media only screen and (max-width: 1024px) {
    .allApptsListContainer-ss{
      overflow-x: auto !important;
    }


}
@media only screen and (max-width: 600px) {
  .allApptsListContainer-ss {
    max-height: 55vh !important;
    min-height: 50vh !important;
  }
}

.allApptsListContainer-ss::-webkit-scrollbar {
  width: 6px !important;

}

.allApptsListContainer-ss::-webkit-scrollbar-track {

  border-radius: 10px !important;
}

.allApptsListContainer-ss::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: var(--primary-new) !important;
}