.pOListSection{
    overflow-y: auto;
    max-height: 60vh !important;

}
.drillDownSection{
    overflow-y: auto;
    max-height: 63vh;

}
.pOListSection-empty{
    max-height: 60vh !important;

}

table  { border-collapse: collapse; width: 100%; }

