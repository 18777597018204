.selectedDockStatusFilters {
    height : auto;
    margin : 0% -4% 0% -1.4%;
    padding : 0.7% 1%;
}

.setFullBorder {
    border: 1px solid var(--load-header-border);
}

.setBorderTop{
    border-top: 1px solid var(--load-header-border);
    /* margin-bottom: -1%; */
}
.setBorderBottom{
    border-bottom: 1px solid var(--load-header-border);
    /* margin-bottom: -1%; */
}
.setBorderBottomsub{
    border-bottom: 1px solid var(--load-header-border);
    margin-bottom: -1%;
}
.selectedFilterItem {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color : var(--primary) !important;
    border: 1px solid var(--primary);
    padding : 0.2% 0.5% 0.2% 0.5%;

}

.dark-mode-selectedFilters{
    color: var(--dark-mode-text-color) !important;
    background-color: var(--dark);
    border: 2px solid var(--dark-mode-border-color);
}

.light-mode{
    background-color: var(--white);
}

.selectedFilterItem :hover{
    cursor: pointer;
}