.tracker-containre {
    border-radius: 8px;
    background: #F5F9FC;
    width: 420px;
    min-height: calc(90vh-200px);

}

/* .apexcharts-legend {
    display: none !important;
} */

#chart {
    display: flex;
    justify-content: center;
    height: 23vh;
    width: -webkit-fill-available;
}


#bar-chart {
    display: flex;
    justify-content: center;
    width: 42%;
}

.responsive-chart-bar{
   width: 100%;
}


.filter-btn {
    border: 0.5px solid #E5E5E5;
    border-radius: 5px;
    opacity: 0.6;
    background: #FFF;
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
    /* width: 100px; */
    height: 32px;
    color: black;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 10px;
}

.filter-btn:hover {
    border-radius: 5px;
    border: 0.5px solid #E5E5E5;
    opacity: 1;
    background: #FFF;
    box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
}

.filter-btn.active {
    background: #10C6F5;
    color: #FFF;
}

.appt-tracker-status {
    width: 65px;
    height: 25px;
    border-radius: 2px;
}

.tracker-completed {

    /* background: #C9F8D6; */
    background: #ccc;

}

.tracker-scheduled {
    background: #C9F8D6;
}

.tracker-deleted {
    background: #FFC7C3;
}

.tracker-rescheduled {
    background: #fcaa3433;
}

.sub-text {
    color: #8C8E8D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;

}

.tracker-counts {
    min-height: 35%;
}

.appt-tracker-ss-nodata {
    width: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
}