.my-footer{
  position: absolute;
  bottom: 0;
  width:100%;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 999;
}


.my-footer.light-mode{
  background-color: transparent;
}
.my-footer.dark-mode{
  background-color: transparent;
}
.powered-by {
  position: fixed;
  bottom: 0;
  right: 0;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: row;
    padding: 5px;
    /* margin-bottom: 2px; */
  }
 
  .powered-by p {
    margin: 0;
    font-size: 12px;
    /* padding-top: 8px; */
    font-weight: 500;
  }
  
  .powered-by img {
    width: 100px;
    height: 20px;
     margin-top: 4px;
    /* margin-top: 10px; */
  }
  