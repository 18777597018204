.renewComponentParentContainer {
    max-height: 80vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 4px;
}

.cardsImage {
    width: 200px !important;
    height: 40px !important;
}

.paytraceIFrameRenew-ss {
    width: 65%;
    padding: 12px;
    display: flexbox;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--white);
    border-radius: 8px;
}

.iFrameRenew {
    width: 90%;
    margin-left: 5% !important;
    margin-right: 5% !important;
}