.appt-search-container {
    width: 100%;
    border-radius: 8px;
    background: #F5F9FC;
}

hr {
    margin: 0px;
}



.form-block-ss p {
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #191919;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.form-control-ss {
    border-radius: 5px;
    border: 0.8px solid #D4D4D4;
    background: #FFF;
    width: 100%;
    height: 34px;
    padding: 5px;
    color: black;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
    letter-spacing: 0.06px;
}

.submit-btn-ss {
    border-radius: 4px;
    background: linear-gradient(180deg, #10C6F5 0%, #12BFEB 100%);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.50) inset;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 156px;
    height: 32px;
    border: none;
    margin: 10px 0;
}

.highlighted-div {
    border: 2px solid #0081A1;
    /* Example border style */
    transition: border-color 0.5s ease;
}