.inputDiv {
    margin-top: 14px;
    width: 95%;
    color: var(--dark) !important;
}

.loginButtonContainer {
    margin-top: 2%;
    width: 90% !important;
}
