.rct-header-root,
.rct-dateHeader-primary {
  background: #D8F2F7 !important;
  color: var(--primary-text) !important;
}

.rct-header-root * {
  color: var(--primary-text) !important;
}

.timeline-scrollbar {
  display: none !important;
}

.react-calendar-timeline .rct-outer {
  height: 60vh !important;
  overflow-y: auto;
}

/* .rct-hl-even,
.rct-hl-odd,
.rct-sidebar-row {
  height: 50px !important;
} */

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.rct-sidebar-row-odd {
  background: transparent !important;
}



.react-calendar-timeline .rct-header-root>div:first-child {
  font-size: 14px !important;
  font-weight: 400 !important;
  align-items: center !important;
  text-align: center !important;
  margin-top: 20px;
  color: var(--white);
}

.ss-icon {
  color: var(--table-selection-text-light) !important;
}

.react-calendar-timeline .rct-outer .rct-sidebar {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
}

/* .react-{
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
} */
/* .react-calendar-timeline .rct-header-root > div:nth-child(1) {
 width: 208px !important;
}

.react-calendar-timeline .rct-outer .rct-sidebar{
  width: 200px !important;
}
.react-calendar-timeline .rct-outer .rct-sidebar > div{
  width: 200px !important;
} */

/* Change the color of items */
.react-calendar-timeline .rct-item {
  /* color: var(--black) !important;
  background-color: #C9F8D6 !important;
  border: 1px solid var(--black) !important; */

  /* background:#C9F8D6 !important;
  color:#35C656 !important;
  border: 1.4px solid var(--scheduled) !important; */
  /* Change text color to white */
}

.deletePopupBkg {
  backdrop-filter: blur(3px);
}

.scheduler-wrapper-ss {
  background: var(--pure-white);
  border-radius: 15px;
  height: 100%;
}

.scheduler-wrapper-ss * {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;

  /* 93.75% */
  letter-spacing: 0.08px;
}

.f-16 {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.schedule-date-picker-ss {
  border-radius: 5px;
  border: 0.5px solid #E5E5E5;
  background: #FFF;
  box-shadow: 0px 1px 2px -0.5px rgba(0, 0, 0, 0.20);
  width: 119px;
  height: 32px;
  font-size: 12px;
  text-align: center;
}

.rct-scroll {
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
}

.code__schedule__blue{
  background-color: rgb(33, 150, 243);
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.code__Leaves{
  background-color: #E39898;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}
.dock-hour-off {
  border-bottom: 1px solid #bbb;
  height: 30px;
  width: 100%;
}
.dock-hour-off-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.default-dock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.react-calendar-timeline .rct-item .rct-item-content {
  padding: 0 1px !important;
}