.pODockStatusButton {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid !important;
    cursor: pointer;
    margin : 0% 0% 1% 0.8%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.dark-mode-buttonsFilters{
    border: 1px solid !important;
    color: var(--white);
    background-color: var(--dark);
}

.light-mode-buttonsFilters{
    border: 1px solid var(--table-header) !important;
    color: var(--dark);
    background-color: var(--white);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.selectedElement-light-mode{
    color : var(--primary) !important;
    border: 1px solid var(--primary) !important;
    background-color: var(--white);
}

.selectedElement-dark-mode{
    color: var(--dark-mode-text-color) !important;
    background-color: var(--dark);
    border: 2px solid var(--dark-mode-border-color) !important;
}