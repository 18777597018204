.faqPopoverContainer{
    background-color: var(--white);
    font-size: 14px;
}

.adjustedFaqText{
    width : 20vw;
    margin-left: 3%;
}

.addButtonFaq{
    width: 7vw;
}

.toggleSwitchFaq{
    width: 6vw;
}

.faqEnableStatus{
    width: 5vw;
}

.faqCreatedOn{
    width: 15vw;
}