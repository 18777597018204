.fileUploadContainer{
    font-size: 16px;
    border: 1px solid var(--dark);
    padding: 4px;
    border-radius: 4px;
    width : auto;
    max-width: 50vw !important;
}

.warningText{
    font-size: 12px;
}
::file-selector-button {
    display: none;
}