 .btn-approve {
     color: var(--white) !important;
     background: var(--table-selection-text-light) !important;
     border-radius: 8px;
     border-color: transparent;
 }

 .btn-approve:hover,
 .btn-approve:focus,
 .btn-approve:active,
 .btn-approve.active {
     background: var(poApproveBtn-hover-light) !important;
 }

 .btn-approve:disabled {
     color: var(--table-header) !important;
     background: var(--table-hover-dark) !important;
 }

 .btn-reschedule.light-mode {
     border-color: var(--dark) !important;
     background: transparent;
     border-radius: 8px;
 }

 .btn-reschedule.light-mode:hover,
 .btn-reschedule.light-mode:focus,
 .btn-reschedule.light-mode:active,
 .btn-reschedule.light-mode.active {
     background: var(--poRescheduleBtn-hover-light) !important;
 }

 .btn-reschedule.light-mode:disabled {
     color: var(--table-border-color) !important;
     background: var(--load-header-border) !important;
     border-color: transparent;
 }

 .btn-reschedule.dark-mode {
     border-color: var(--white) !important;
     background: transparent;
     border-radius: 8px;
 }

 .btn-reschedule.dark-mode:hover,
 .btn-reschedule.dark-mode:focus,
 .btn-reschedule.dark-mode:active,
 .btn-reschedule.dark-mode.active {
     background: var(--table-header-text) !important;
     color: var(--load-header-border) !important;
 }

 .btn-reschedule.dark-mode:disabled {
     color: var(--table-header) !important;
     background: var(--table-hover-dark) !important;
     border-color: transparent;
 }

 .poApproveBtn {
     flex-grow: 0.2
 }

 .dropDownContainer {
     flex-grow: 0.42
 }

 .assignDockText {
     font-weight: 400;
     font-size: 14px;
     line-height: 21px;
 }