.ss-appt-container {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
    padding: 10px;
    width: 35vw;
    max-width: 35vw;
    height: 100%;
    max-height: 100%;

    position: relative;
}

.overflowY-auto {
    overflow-y: auto;
}

.carrier-aapt-card-container {
    max-height: 68vh;
    padding: 0 5px;
}

.carrier-aapt-card-container::-webkit-scrollbar {
    width: 6px;

}

.carrier-aapt-card-container::-webkit-scrollbar-track {

    border-radius: 10px;
}

.carrier-aapt-card-container::-webkit-scrollbar-thumb {
    border-radius: 10px;


    background: #34c8ed;
}

.btn-carrier-appt-seconday {
    background: white;
    box-shadow: 0 0 2px black;
    color: black;
    padding: 5px 8px;
    outline: none;
    border: none;
    border-radius: 6px;
}

.search-container-ss {
    position: relative;

}

.search-control-ss {
    padding: 2px 28px !important;
}

.search-control-ss-icon {
    position: absolute;
    left: 0;
    padding: 10px 8px;

}



.ss-appt-container-float-btn {
    height: 100px;
    position: absolute;
    bottom: 1%;
    right: 5%;
    width: -webkit-fill-available;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 3.44%, #FFF 90.08%);
}

/* .ss-appt-container-float-btn button {
    background: rgba(0, 0, 0, 0.8);
} */

@media only screen and (max-width: 1024px) {
.ss-appt-container{
    width: 100%;
    max-width: 100%;
    margin-top: 60%;
    margin-bottom: 10%;
    display: none;
}
}