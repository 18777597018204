.recommendation-wrapper {
    width: 31vw;
    border-radius: 8px;
    height: 90%;
    text-align: center;
}

.time-slot-ss {
    border-radius: 4px;
    background: #F5F6F7;
    border: 0.4px solid #F5F6F7;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.20) inset;
    width: 10vw;
    margin: auto;
    padding: 8px;
    cursor: pointer;
}

.time-slot-ss-selected,
.time-slot-ss:hover {
    border-radius: 4px;
    color: white;
    box-shadow: 0px -2px 2px 0px rgba(255, 255, 255, 0.20) inset;
    width: 10vw;
    margin: auto;
    padding: 8px;
    cursor: pointer;
    border: 0.4px solid #4FA2B4;
    background: #3DBED5;
}

.ss-timezone {
    color: #383838;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    /* 166.667% */
    letter-spacing: 0.045px;
}

.ss-timezone-sub {
    color: #383838;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 166.667% */
    letter-spacing: 0.045px;
}

.slots-wrapper {
    max-height: 50vh !important;
    overflow-y: auto !important;
}

.slots-wrapper::-webkit-scrollbar {
    width: 8px;
}

.slots-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
}

.slots-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #34c8ed;
}

.overflowX-hidden {
    overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
    /* .recommendation-wrapper{
        flex-direction: column;
    } */
    .po-schedule-right-ss{
        width: 100% !important;
        margin: 0 !important;
    }
    /* .slots-wrapper{
        max-height: none !important;
        overflow-y: hidden !important;
    } */
    .time-slot-ss,.time-slot-ss:hover{
        width: 100%;
    }
    .time-slot-ss-selected{
        width: 100% !important;
    }
}
@media only screen and (max-width: 600px) {
    .recommendation-wrapper{
        flex-direction: column;
        height: 100%;
    }
    .slots-wrapper{
        max-height: none !important;
        overflow-y: hidden !important;
        height: auto !important;
    }
    .timeslots-container-ss{
        overflow: hidden !important;
        height: auto;
        max-height: none;
    }
}

@media only screen and (min-width: 600px) and (max-width:1024px) {
    .slots-wrapper{
        max-height: 50vh !important;
        overflow-y: auto !important;
    }
    .timeslots-container-ss{
        overflow: hidden !important;
        height: auto;
        max-height: none;
    }
    .recommendation-wrapper{
        height: 100%;
    }
}