.taskItemsSection{
    background-color: transparent;
    
}

.taskItemsSection.light-mode{
    border-left: 15px solid var(--defaultDashboardLightBorder);
}

.taskItemsSection.dark-mode{
    border-left: 15px solid var(--black);
}

.taskItemLink{
    text-decoration: none;
}

.taskItemTitle.disable {
    opacity: 0.4 !important;
}